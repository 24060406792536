<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des processus métier
      </span>

      <div class="row m-0">
        <div class="col-12 d-flex justify-content-end p-0">
          <custom-add-button
            :disabled="disabled || (!capacitesList.length ? true : false)"
            @click="addItem"
            text="Ajouter un processus métier"
          ></custom-add-button>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive
          show-empty
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          bordered
          striped
          :busy="loading"
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(capaciteMetiers)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value ? data.value.name : "-" }}
            </span>
            <div v-else>
              <v-select
                appendToBody
                v-model="newPr.capacite"
                label="text"
                :reduce="(capacite) => capacite.value"
                placeholder="Capacité métier"
                :options="capacitesList"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newPr.capacite.$error && $v.newPr.capacite.$dirty,
                }"
                :disabled="data.item.id ? true : false"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newPr.capacite.$dirty"
              >
                {{ !$v.newPr.capacite.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name: 'show-job-process',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
            >
              {{ data.value }}
            </b-link>
            <div v-else>
              <v-select
                appendToBody
                label="name"
                style="padding: 0 !important"
                placeholder="Processus métier"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newPr.name.$error && $v.newPr.name.$dirty,
                }"
                v-model="newPr.name"
                :options="processusList"
                :selectable="({ selectable }) => selectable"
                :reduce="
                  (process) => {
                    newPr.niveau = process.niveau;
                    return process;
                  }
                "
                @input="newPr.niveau = null"
                :disabled="data.item.id ? true : false"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newPr.name.$dirty"
              >
                {{ !$v.newPr.name.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(niveau)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                type="number"
                :value="0"
                min="0"
                placeholder="Niveau"
                :class="{
                  'is-invalid':
                    $v.newPr.niveau.$error && $v.newPr.niveau.$dirty,
                }"
                disabled
                v-model="newPr.niveau"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newPr.niveau.$dirty"
              >
                {{ !$v.newPr.niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(isPrincipal)="data">
            <b-form-checkbox
              v-if="!data.item.hasOwnProperty('editMode')"
              disabled
              :checked="data.value"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else
              :checked="newPr.selected"
              v-model="newPr.selected"
            ></b-form-checkbox>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { required } from "vuelidate/lib/validators";
import { APPLICATION_URL } from "@/helpers/constURL";
import { groupBy } from "lodash";

export default {
  components: { CustomAddButton, TableActions },
  props: {
    processus: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    newPr: {
      name: {
        required,
        // minLength: minLength(5),
      },
      niveau: {
        required,
      },
      capacite: {
        required,
      },
    },
  },
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    showGoals: false,
    loading: true,
    newPr: {
      capacite: "",
      name: "",
      niveau: "",
      selected: "",
    },
    tableFields: [
      { key: "capaciteMetiers", label: "Capacité métier", sortable: true },
      { key: "name", label: "Processus métier", sortable: true },
      { key: "niveau", label: "Niveau", sortable: true },
      { key: "isPrincipal", label: "Processus principal", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
  }),
  created() {
    this.$store
      .dispatch("application/fetchApplicationProcessus", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });

    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
  },
  computed: {
    ...mapGetters("application", ["PROCESSUS"]),
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]),
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]),

    rows() {
      return this.tableItems.length;
    },
    capacitesList() {
      return this.CAPACITE_METIERS.filter(
        ({ processus }) =>
          processus.filter(
            (process) =>
              process.niveau == 2 &&
              !this.tableItems.find((item) => item["@id"] == process["@id"])
          ).length
      ).map((capacite) => ({
        value: capacite["@id"],
        text: capacite.name,
      }));
    },
    processusList() {
      var processus = [];
      if (this.newPr.capacite) {
        processus = this.PROCESSUS_METIERS.filter(
          (processus) =>
            (processus.capaciteMetiers
              ? processus.capaciteMetiers["@id"] == this.newPr.capacite
              : false) &&
            processus.niveau === 2 &&
            processus.parent &&
            !this.PROCESSUS.processus.find(
              (item) => item["@id"] === processus["@id"]
            )
        );

        var processusTable = [];

        processus = processus.filter((process) => process.niveau === 2);
        // window.console.table(processus, "processus level 2"); // processus de niveau 2

        Object.keys(groupBy(processus, "parent.id")).map((label) => {
          var parent = groupBy(processus, "parent.id")[label][0].parent; // parent du processus de niveau 2
          //
          window.console.log(parent);
          processusTable.push({
            fullLabel: parent ? parent.name : "-",
            ...parent,
            selectable: false,
          });
          groupBy(processus, "parent.id")[label].map((process) => {
            processusTable.push({
              fullLabel: `${parent.name}_${process.name}`,
              ...process,
              selectable: true,
            });
          });
        });
        return processusTable.filter(
          (process) =>
            process.selected &&
            !this.tableItems.find((item) =>
              item["@id"] ? item["@id"] == process["@id"] : false
            )
        )
          ? processusTable.filter(
              (process) =>
                !this.tableItems.find((item) =>
                  item["@id"] ? item["@id"] == process["@id"] : false
                )
            )
          : [];
      }
      return [];
    },
  },
  methods: {
    editItem(data) {
      window.console.log(data);
      this.$store
        .dispatch(
          "application/fetchApplicationProcessus",
          this.$route.params.id
        )
        .then(() => {
          this.newPr.name = data;
          this.newPr.niveau = data.niveau;
          this.newPr.capacite = data.capaciteMetiers
            ? data.capaciteMetiers["@id"]
            : null;

          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === data.id
              ? {
                  ...challenge,
                  editMode: true,
                  Modify: true,
                }
              : challenge;
          });
        });
    },
    cancelItem() {
      if (
        this.newPr.name === "" &&
        this.newPr.niveau === "" &&
        this.newPr.capacite === ""
      ) {
        var test = this.tableItems.find(
          (contributer) => contributer.Modify == true
        );
        if (test == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch(
            "application/fetchApplicationProcessus",
            this.$route.params.id
          );
          this.$v.$reset();
        }
      } else
        (this.newPr.name = ""),
          (this.newPr.niveau = ""),
          (this.newPr.capacite = "");
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var processus = this.PROCESSUS_METIERS.find(
            ({ id }) => id === item.id
          );
          var applications = processus
            ? processus.applications
                .filter((app) => app.id != this.$route.params.id)
                .map((app) => app["@id"])
            : [];
          window.console.log(processus);
          this.$store
            .dispatch("processusMetier/updateProcessusMetier", {
              id: item.id,
              applications: applications,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationProcessus",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                  this.$v.$reset();
                });
            });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (item.id)
          this.$store
            .dispatch("processusMetier/updateProcessusMetier", {
              id: item.id,
              niveau: parseInt(this.newPr.niveau),
              isPrincipal: this.newPr.selected,
            })
            .then(() => {
              Swal.fire("Le bénéficiaire est mis à jour !", "", "success");
              this.$store
                .dispatch(
                  "application/fetchApplicationProcessus",
                  this.$route.params.id
                )
                .then(() => {
                  (this.newPr.name = ""),
                    (this.newPr.niveau = ""),
                    (this.newPr.capacite = "");
                  this.$v.$reset();
                });
            });
        else {
          window.console.log(this.newPr);
          var applications = this.newPr.name.applications.map(
            (application) => application["@id"]
          );
          applications.push(`/api/${APPLICATION_URL}/${this.$route.params.id}`);

          this.$store
            .dispatch("processusMetier/updateProcessusMetier", {
              applications: applications,
              niveau: parseInt(this.newPr.niveau),
              id: this.newPr.name.id,
              isPrincipal: this.newPr.selected ? true : false,
            })
            .then(() => {
              Swal.fire("Le processus est bien créé !", "", "success");
              this.$store
                .dispatch(
                  "application/fetchApplicationProcessus",
                  this.$route.params.id
                )
                .then(() => {
                  (this.newPr.name = ""),
                    (this.newPr.niveau = ""),
                    (this.newPr.capacite = "");
                  this.$v.$reset();
                });
            });
        }
      }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
  },
  watch: {
    PROCESSUS() {
      this.tableItems = this.PROCESSUS.processus;
    },
  },
};
</script>

<style></style>
