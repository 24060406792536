var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row m-0 p-0 table_responsivo"},[_c('div',{staticClass:"col-sm-4 m-0 p-0",attrs:{"id":"gantt-table"}},[_vm._m(0),_vm._l((_vm.ganttData),function(item,index){return _c('div',{key:index + '1245',staticClass:"sd_menu_row d-flex align-items-center",style:({
          backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
        })},[_c('p',{staticClass:"fl_2 pl-2 my-auto"},[(item.feuilleRoute)?_c('router-link',{attrs:{"to":{
              name: 'show-fdr',
              params: {
                slug: item.feuilleRoute.slug || 'abcde',
                id: item.feuilleRoute.id,
              },
            }}},[_vm._v(_vm._s(item.feuilleRoute.name))]):_c('span',[_vm._v("-")])],1),_c('p',{staticClass:"fl_2 my-auto"},[_c('router-link',{attrs:{"to":{
              name: 'show-initiative',
              params: {
                slug: item.slug || 'abcde',
                id: item.id,
              },
            }}},[_vm._v(_vm._s(item.libelle))])],1),_c('div',{staticClass:"fl_2 d-flex align-items-center"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                item.responsable.firstname + ' ' + item.responsable.lastname
              ),expression:"\n                item.responsable.firstname + ' ' + item.responsable.lastname\n              ",modifiers:{"hover":true}}],staticClass:"mr-1",staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd","height":"30px","width":"30px"},attrs:{"src":_vm.IMG_URL + item.responsable.avatarUrl}},[(!item.responsable.avatarUrl)?_c('div',{staticStyle:{"width":"25px","height":"25px","border-radius":"15px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(item.responsable.firstname[0].toUpperCase() + item.responsable.lastname[0].toUpperCase())+" ")]):_vm._e()])],1)])])}),(_vm.loadingData)?_c('div',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]):(!_vm.items.length)?_c('div',[_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]):_vm._e()],2),_c('div',{staticClass:"col-sm-8 m-0 p-0"},[(_vm.items.length)?_c('frappe-gantt',{staticClass:"w-100 h-100",attrs:{"view-mode":_vm.mode,"tasks":_vm.ganttData.map((item) => ({
            id: `initiative-${item.id}`,
            progress: item.avancement,
            name: `${item.avancement}%`,
            start: _vm.formatDate(item.startedAt),
            end: _vm.formatDate(item.endingAt),
            taskId: item.id,
          }))},on:{"task-updated":function($event){return _vm.debugEventLog.push($event)},"task-progress-change":function($event){return _vm.debugEventLog.push($event)},"task-date-updated":_vm.updateInitiativeDate,"task-progress-updated":_vm.updateInitiativeProgress}}):_vm._e()],1)]),_c('b-pagination',{attrs:{"total-rows":_vm.items.length,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.ganttCurrentPage),callback:function ($$v) {_vm.ganttCurrentPage=$$v},expression:"ganttCurrentPage"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_sd_menu d-flex align-items-center"},[_c('p',{staticClass:"fl_2 m-auto pl-2"},[_vm._v("FdR")]),_c('p',{staticClass:"fl_2 m-auto"},[_vm._v("Initiative")]),_c('p',{staticClass:"fl_2 m-auto"},[_vm._v("Responsable / PO")])])
}]

export { render, staticRenderFns }