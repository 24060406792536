<template>
  <div>
    <p
      class="text-secondary my-auto mr-auto"
      style="margin-top: -56px !important"
    >
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Application :
      </span>
      <span style="font-size: 22px">{{ APPLICATION.name }}</span>
    </p>
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche objet:</span>
      <b-form-input placeholder="Cadre" v-model="searchQuery.cadre" />
      <b-button
        class="ml-1"
        :disabled="!selectedObjects.length"
        @click="confirmObjects"
      >
        Confirmer
      </b-button>
      <!-- <button @click="$refs.objectsTable.clearSelected()">  fffff</button> -->
    </div>
    <div v-if="false" class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{selectedObjects}} -->
    <!-- {{$refs.objectsTable}} -->
    <b-table
      :items="tableItems"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
      bordered
      striped
      ref="objectsTable"
      selectable
      select-mode="multi"
      @row-selected="onRowSelected"
    >
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(business_object)="data">
        <b-link
          :to="{
            name: 'show-business-object',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.item ? data.item.name : "-" }}
        </b-link>
      </template>
      <template v-slot:cell(sensitivity)="data">
        <span>
          <b-badge :variant="sensible(data.item) ? 'danger' : 'success'">{{
            sensible(data.item)
          }}</b-badge>
        </span>
      </template>
      <template v-slot:cell(dcp)="data">
        <b-badge :variant="dcp(data.item) === 'Oui' ? 'danger' : 'success'">{{
          dcp(data.item)
        }}</b-badge>
      </template>

      <template #cell(selection)="{ rowSelected }">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            v-model="rowSelected"
            :checked="rowSelected"
            disabled
          ></b-form-checkbox>
          <!-- {{rowSelected}} -->
        </div>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @editItem="editItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @cancelItem="cancelItem"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <!-- {{ OBJETS }} -->
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
// import Swal from "sweetalert2";
// import { APPLICATION_URL } from "@/helpers/constURL";
import { mapGetters } from "vuex";
// import CustomAddButton from "@/components/custom/CustomAddButton";
import { maxBy } from "lodash";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    TableActions,
    //   CustomAddButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    showGoals: false,
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    concept: null,
    objet: null,
    // loading: true,
    tableFields: [
      { key: "business_object", label: "Objet Métier", sortable: true },
      { key: "dcp", label: "DCP", sortable: true },
      { key: "sensitivity", label: "Sensibilité", sortable: true },
      {
        key: "selection",
        label: "Sélection",
        thStyle: { textAlign: "center" },
      },
    ],
    tableItems: [],
    searchQuery: {
      cadre: "",
    },
    selectedRole: "",
    selectedObjects: [],
  }),
  validations: {
    objet: {
      required,
    },
  },
  methods: {
    onRowSelected(items) {
      this.selectedObjects = items;
    },
    maxBy,
    dcp(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.dcp ? attribut.dcp === "Non" : false
        )
        ? "Non"
        : "Oui";
    },
    sensible(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.sensible ? attribut.sensible === 0 : true
        )
        ? 0
        : maxBy(objet.attributs, (attribut) => attribut.sensible || 0).sensible;
    },
    confirmObjects() {
      this.$emit(
        "confirmObjects",
        this.selectedObjects.map((obj) => obj["@id"])
      );
      this.$emit("close");
    },
    selectRow(index) {
      this.$refs.objectsTable.selectRow(index);
    },
  },
  created() {
    // this.$store
    //   .dispatch("application/fetchApplicationObjets", this.$route.params.id)
    //   .then(() => {
    //     this.loading = false;
    //   });
  },
  computed: {
    ...mapGetters("application", ["APPLICATION", "FLUXS"]),
    ...mapGetters("objet", ["OBJETS"]),

    rows() {
      return this.tableItems.length;
    },

    objectsList() {
      return this.OBJETS.filter(
        (objet) => !this.tableItems.find((item) => item["@id"] === objet["@id"])
      );
    },
    selectedObject() {
      return this.objet
        ? this.OBJETS.find((obj) => obj["@id"] === this.objet)
        : null;
    },
  },
  mounted() {
    this.tableItems = this.OBJETS;
    this.selectedObjects = this.OBJETS.filter((obj) => {
      return this.selected.includes(obj["@id"]);
    });
    this.selectRow(1);
    this.$refs.objectsTable.selectAllRows();
  },

  watch: {
    OBJETS() {
      this.tableItems = this.OBJETS;
      this.selectedObjects = this.OBJETS.filter((obj) => {
        return this.selected.includes(obj["@id"]);
      });
      this.selectRow(1);
    },
  },
};
</script>
