<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <div class="d-flex">
          <div class="my-auto mr-2">
            <p class="h4 text-secondary p-0 m-0">Transformation en cours</p>
          </div>
          <div class="ml-1 my-auto">
            <DropDownCustom
              :items="['Tableau', 'Gantt']"
              :selected="selected"
              @changeValue="onViewChange"
            />
          </div>
          <div class="my-auto pl-2 mx-0" v-if="selected == 1">
            <b-dropdown
              id="dropdown-1"
              :text="selectedView.text"
              :class="`custom-drop my-auto btn-outline-info ${selectedView.color} `"
            >
              <b-dropdown-item
                class="col-sm-6 col-md-auto m-0 p-0"
                v-for="(view, index) in ganttView"
                :key="index"
              >
                <button
                  @click="changeView(view, index)"
                  type="button"
                  :class="`btn ${view.color} btn-fw col-sm-12`"
                >
                  {{ view.text }}
                </button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <div v-show="selected === 0" class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          :per-page="perPage"
          :busy="loading"
          responsive
          show-empty
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template #cell(fdr)="data">
            <b-link
              :to="{
                name: 'show-fdr',
                params: {
                  id: data.item.initiative.feuilleRoute.id,
                  slug: data.item.initiative.feuilleRoute.slug || 'abcde',
                },
              }"
              v-if="data.item.initiative"
            >
              {{ data.item.initiative.feuilleRoute.name }}
            </b-link>
          </template>
          <template #cell(initiative)="data">
            <b-link
              :to="{
                name: 'show-initiative',
                params: {
                  id: data.item.initiative.id,
                  slug: data.item.initiative.slug || 'abcde',
                },
              }"
              v-if="data.value"
            >
              {{ data.value.libelle }}
            </b-link>
          </template>
          <template #cell(po)="data">
            <span v-if="data.item.initiative">
              <TableResponsable
                :responsable="data.item.initiative.responsable"
              />
            </span>
          </template>
          <template #cell(patrimoine)="data">
            <span v-if="data.item.initiative.feuilleRoute.patrimoine">
              {{ data.item.initiative.feuilleRoute.patrimoine.name || "-" }}
            </span>
          </template>
          <template #cell(description)="data">
            <span>
              {{ data.value || "-" }}
            </span>
          </template>
          <template #cell(end_date)="data">
            <span>
              {{ data.value || "-" }}
            </span>
          </template>
          <template #cell(type)="data">
            <b-badge
              :variant="
                data.value.toLowerCase() === 'création'
                  ? 'success'
                  : data.value.toLowerCase() === 'evolution'
                  ? 'warning'
                  : 'danger'
              "
            >
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
      </div>
      <!-- {{ TRANSFORMATIONS }} -->
    </div>
    <div class="row" v-show="selected === 1">
      <!-- {{ ganttItems }} -->
      <Gantt
        v-if="$route.params.id"
        :items="ganttItems"
        :loadingData="loading"
        :mode="mode"
        class="col-12"
      />
      <div
        v-else
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
  </div>
</template>

<script>
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { APPLICATION_URL } from "@/helpers/constURL";
import DropDownCustom from "./DropDown.vue";
import { mapGetters } from "vuex";
import Gantt from "@/pages/transformation/initiative/components/Gantt.vue";

import Swal from "sweetalert2";
export default {
  components: { TableResponsable, DropDownCustom, Gantt },
  props: {
    transformations: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showGoals: false,
    selected: 0,
    perPage: 5,
    currentPage: 1,
    tableFields: [
      { key: "fdr", label: "Feuilles de routes", sortable: true },
      { key: "initiative", label: "Initiatives", sortable: true },
      { key: "po", label: "PO", sortable: true },
      { key: "patrimoine", label: "Patrimoine", sortable: true },
      { key: "type", label: "Type Impact", sortable: true },
      {
        key: "description",
        label: "Description Impact",
        sortable: true,
      },
      { key: "end_date", label: "Date de fin", sortable: true },
    ],
    tableItems: [],
    loading: true,
    mode: "day",
    selectedView: {
      text: "Jour",
      id: 1,
      color: " btn-inverse-primary",
    },
    ganttView: [
      {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
      {
        text: "Semaine",
        id: 2,
        color: "btn-inverse-danger ",
      },
      {
        text: "Mois",
        id: 3,
        color: "btn-inverse-info ",
      },
    ],
  }),
  created() {
    this.$store
      .dispatch(
        "application/fetchApplicationTransformations",
        this.$route.params.id
      )
      .then(() => {
        this.loading = false;
      });

    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchBeneficiaireTypes");
  },
  computed: {
    ...mapGetters("application", ["TRANSFORMATIONS"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("type", ["BENEFICIAIRE_TYPES"]),

    rows() {
      return this.tableItems.length;
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    ganttItems() {
      // var table = [];
      // if (
      //   this.TRANSFORMATIONS &&
      //   this.TRANSFORMATIONS.impacts &&
      //   this.TRANSFORMATIONS.impacts.length
      // ) {
      // this.tableItems.map((impact) =>
      //   impact.initiatives.map((initiative) => {
      //     table.push({
      //       ...initiative,
      //       // feuilleRoute: fdr,
      //     });
      //   })
      // );
      // }
      return this.tableItems.map(({ initiative }) => initiative);
    },
  },
  methods: {
    changeView: function (view, val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView.text = view.text;
    },
    onViewChange(value) {
      this.selected = value;
    },
    editItem(index) {
      this.$store
        .dispatch(
          "application/fetchApplicationTransformations",
          this.$route.params.id
        )
        .then(() => {
          this.tableItems = this.tableItems.map((challenge, i) => {
            return index === i
              ? {
                  ...challenge,
                  editMode: true,
                }
              : challenge;
          });
        });
    },
    cancelItem(item) {
      if (
        item.name === "" &&
        item.type === "" &&
        item.organisation === null &&
        item.volume === null
      )
        this.$store.dispatch(
          "application/fetchApplicationTransformations",
          this.$route.params.id
        );
      else
        (item.name = ""),
          (item.type = ""),
          (item.organisation = null),
          (item.volume = null);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("capaciteSi/deleteCapacite", id).then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationTransformations",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    confirmItem(item) {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      delete item.editMode;

      if (item.id)
        this.$store
          .dispatch("capaciteSi/updateCapacite", {
            id: item.id,
            name: item.name,
            niveau: parseInt(item.niveau),
          })
          .then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationTransformations",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("La capacité est mise à jour !", "", "success");
              });
          });
      else
        this.$store
          .dispatch("capaciteSi/createCapacite", {
            applications: [`/api/${APPLICATION_URL}/${this.$route.params.id}`],
            name: item.name,
            niveau: parseInt(item.niveau),
            description: "",
          })
          .then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationTransformations",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("La capacité est bien créée !", "", "success");
              });
          });
      //  }
    },
    addItem() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
            },
            ...this.tableItems,
          ]
        : this.tableItems;
    },
  },
  watch: {
    TRANSFORMATIONS() {
      this.tableItems = this.TRANSFORMATIONS.impacts;
    },
  },
};
</script>

<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
</style>
