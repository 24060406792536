<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Application :
      </span>
      <span style="font-size: 22px">{{ libelle }}</span>
    </p>

    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche Fonction:</span>
      <v-select
        class="w-100"
        placeholder="Nom"
        :options="responsables"
        v-model="selectedUser"
        :reduce="(responsable) => responsable.value"
        label="text"
      >
        <template #no-options> Liste vide</template>
      </v-select>

      <b-button
        class="ml-1"
        @click="$emit('confirmfonction', options) && $emit('close')"
      >
        Confirmer
      </b-button>
    </div>

    <b-table
      :fields="fields"
      :items="filteredUsers"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            v-model="data.item.selected"
            class="mb-3"
            value-field="item"
            :checked="true"
            @change="check(data.item['@id'])"
          ></b-form-checkbox>
        </div>
      </template>
      <template #cell(name)="data">
        <span>{{ data.value }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sortBy: "id",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedGuest: [],
      selectedUser: null,
      loading: true,
      options: [],
      fields: [
        { key: "name", label: "Fonction" },
        // { key: "organisation", label: "Organisation" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("fonction/fetchAllFonctions").then(() => {
      this.loading = false;
      this.options = this.selected ? this.selected : [];
      window.console.log(this.options);
    });
  },
  computed: {
    ...mapGetters("fonction", ["FONCTIONS"]),

    responsables() {
      //return array of responsables
      return this.FONCTIONS.map((responsable) => ({
        ...responsable,
        value: responsable.id,
        text: responsable.name,
      }));
    },
    filteredUsers() {
      return this.selectedUser
        ? this.FONCTIONS.filter((user) => user.id === this.selectedUser).map(
            (data) => ({
              ...data,

              selected: this.options
                ? this.options.find((selected) => selected === data["@id"])
                  ? true
                  : false
                : false,
            })
          )
        : this.FONCTIONS.map((data) => ({
            ...data,
            selected: this.options
              ? this.options.find((selected) => selected === data["@id"])
                ? true
                : false
              : false,
          }));
    },

    rows() {
      return this.FONCTIONS ? this.FONCTIONS.length : 0;
    },
  },
  methods: {
    check(data) {
      var x = this.options.find((zx) => zx == data);
      if (!x) {
        this.options.push(data);
      } else {
        let filter = this.options.filter((z) => z !== data);
        this.options = filter;
      }
    },
  },
  props: {
    libelle: String,
    selected: Array,
  },
};
</script>

<style></style>
