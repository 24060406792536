<template>
  <div>
    <div class="row m-0 p-0 table_responsivo">
      <div class="col-sm-4 m-0 p-0" id="gantt-table">
        <div class="header_sd_menu d-flex align-items-center">
          <p class="fl_2 m-auto pl-2">FdR</p>
          <p class="fl_2 m-auto">Initiative</p>
          <p class="fl_2 m-auto">Responsable / PO</p>
          <!-- <p class="fl_2 m-auto text-center">%</p> -->
        </div>
        <div
          v-for="(item, index) in ganttData"
          :key="index + '1245'"
          class="sd_menu_row d-flex align-items-center"
          :style="{
            backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
          }"
        >
          <p class="fl_2 pl-2 my-auto">
            <router-link
              v-if="item.feuilleRoute"
              :to="{
                name: 'show-fdr',
                params: {
                  slug: item.feuilleRoute.slug || 'abcde',
                  id: item.feuilleRoute.id,
                },
              }"
              >{{ item.feuilleRoute.name }}</router-link
            >
            <span v-else>-</span>
          </p>
          <p class="fl_2 my-auto">
            <router-link
              :to="{
                name: 'show-initiative',
                params: {
                  slug: item.slug || 'abcde',
                  id: item.id,
                },
              }"
              >{{ item.libelle }}</router-link
            >
          </p>
          <div class="fl_2 d-flex align-items-center">
            <div class="d-flex">
              <b-avatar
                v-b-tooltip.hover="
                  item.responsable.firstname + ' ' + item.responsable.lastname
                "
                style="
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                  height: 30px;
                  width: 30px;
                "
                class="mr-1"
                :src="IMG_URL + item.responsable.avatarUrl"
              >
                <div
                  v-if="!item.responsable.avatarUrl"
                  style="
                    width: 25px;
                    height: 25px;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{
                    item.responsable.firstname[0].toUpperCase() +
                    item.responsable.lastname[0].toUpperCase()
                  }}
                </div>
              </b-avatar>
            </div>
          </div>
          <!-- <p class="fl_2">
            {{ item.progress }}
          </p> -->
        </div>
        <!-- FOR LOADING ANIMATIONS -->
        <div v-if="loadingData">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </div>
        <!-- SHOW IF TABLE IS EMPTY -->
        <div v-else-if="!items.length">
          <div class="text-center text-gray">Table vide</div>
        </div>
      </div>

      <div class="col-sm-8 m-0 p-0">
        <frappe-gantt
          :view-mode="mode"
          v-if="items.length"
          :tasks="
            ganttData.map((item) => ({
              id: `initiative-${item.id}`,
              progress: item.avancement,
              name: `${item.avancement}%`,
              start: formatDate(item.startedAt),
              end: formatDate(item.endingAt),
              taskId: item.id,
            }))
          "
          @task-updated="debugEventLog.push($event)"
          @task-progress-change="debugEventLog.push($event)"
          @task-date-updated="updateInitiativeDate"
          @task-progress-updated="updateInitiativeProgress"
          class="w-100 h-100"
        />
      </div>
    </div>
    <b-pagination
      v-model="ganttCurrentPage"
      :total-rows="items.length"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import FrappeGantt from "@/components/custom/frappe-gantt.vue";
import moment from "moment";
import { IMG_URL } from "@/helpers/services/api";
import { slice } from "lodash";

export default {
  name: "Gantt",
  components: {
    FrappeGantt,
  },
  props: {
    items: Array,
    mode: String,
    loadingData: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data: () => ({
    debugEventLog: [],
    ganttCurrentPage: 1,
    IMG_URL,
  }),
  methods: {
    formatDate(date) {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
    updateInitiativeDate: function (task, start, end) {
      this.$store.dispatch("initiative/updateInitiative", {
        id: task.taskId,
        startedAt: moment(start).format("DD/MM/YYYY"),
        endingAt: moment(end).format("DD/MM/YYYY"),
      });
    },

    updateInitiativeProgress: function (task, progress) {
      this.$store.dispatch("initiative/updateInitiative", {
        id: task.taskId,
        avancement: progress,
      });
    },
  },
  computed: {
    ganttData() {
      return slice(
        this.items,
        (this.ganttCurrentPage - 1) * 10,
        this.ganttCurrentPage * 10 - 1
      );
    },
  },
  updated: function () {
    let progresses = document.querySelectorAll(".bar-progress");
    if (progresses.length > 0) {
      progresses.forEach((it) => {
        var avancement = parseInt(
          it.parentElement.lastElementChild.innerHTML.split("%")[0]
        );
        var color =
          avancement > 20 && avancement <= 70
            ? "#f5f245"
            : avancement > 70
            ? "#23d92c"
            : "#f5ba45";

        it.style.fill = color;
      });
    }
  },
};
</script>

<style scopped>
.top_gantt_controls {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 10px;
}
.header_sd_menu {
  height: 60px;
  background: #37d3bc !important;
  width: 100%;
  color: white;
  border: 0;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.fl_1 {
  flex: 1;
}

.fl_2 {
  flex: 2;
}

.fl_3 {
  flex: 3;
}

.gantt .grid-header {
  fill: #37d3bc !important;
  stroke: #e0e0e0;
  stroke-width: 1.4;
}
#gantt-table thead {
  background: #37d3bc !important;
}
.gantt .lower-text {
  fill: white;
}
.gantt .upper-text {
  fill: white;
}
.gantt .grid-row {
  fill: #ffffff;
}
</style>
