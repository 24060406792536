<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Application :
      </span>
      <span style="font-size: 22px">{{ name }}</span>
    </p>

    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0"
        >Liste des Fonctions : {{ libelle }}</span
      >
    </div>
    <div class="md-col-12 p-0" style="margin-top: -25px"><hr /></div>
    <div></div>
    <b-table
      :fields="fields"
      :items="items"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(name)="data">
        <span>
          {{ data.value ? data.value : "-" }}
        </span>
      </template>
      <template v-slot:cell(description)="data">
        <span>
          {{ data.value ? data.value : "-" }}
        </span>
      </template>
      <template v-slot:cell(entrepriseValeur)="data">
        <span>
          {{ data.value ? data.value : "-" }}
        </span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sortBy: "id",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedGuest: [],
      selectedUser: null,
      loading: true,
      options: [],
      fields: [
        { key: "name", label: "Fonctions", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "entrepriseValeur", label: "Entreprise Valeur", sortable: true },
      ],
    };
  },

  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
  },

  props: {
    libelle: String,
    items: Array,
    name: String,
  },
};
</script>

<style></style>
