<template>
  <div>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des capacités
      </span>

      <div class="d-flex justify-content-end">
        <CustomAddButton
          :disabled="disabled || (!capacitesList.length ? true : false)"
          @click="addItem"
          text="Ajouter un capacité"
        ></CustomAddButton>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive
          bordered
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          striped
          show-empty
          :busy="loading"
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-infosys-capacity',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              {{ data.value }}
            </b-link>
            <div v-else>
              <v-select
                label="fullLabel"
                placeholder="Capacité SI"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newCap.name.$error && $v.newCap.name.$dirty,
                }"
                v-model="newCap.name"
                appendToBody
                @input="getLevel"
                :options="capacitesList"
                :selectable="({ selectable }) => selectable"
                :disabled="data.item.id ? true : false"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCap.name.$dirty"
              >
                {{
                  !$v.newCap.name.required
                    ? "champ obligatoire"
                    : !$v.newCap.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newCap.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(niveau)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                type="number"
                :value="0"
                min="0"
                disabled
                placeholder="Niveau"
                :class="{
                  'is-invalid':
                    $v.newCap.niveau.$error && $v.newCap.niveau.$dirty,
                }"
                v-model="newCap.niveau"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCap.niveau.$dirty"
              >
                {{ !$v.newCap.niveau.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(isPrincipal)="data">
            <b-form-checkbox
              v-if="!data.item.hasOwnProperty('editMode')"
              :disabled="disabledCheckbox == true ? true : false"
              :checked="data.value"
            ></b-form-checkbox>
            <b-form-checkbox
              v-else
              :checked="newCap.checked"
              v-model="newCap.checked"
            ></b-form-checkbox>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { minLength, required } from "vuelidate/lib/validators";
import { groupBy, uniqBy } from "lodash";
import { APPLICATION_URL } from "@/helpers/constURL";
export default {
  components: {
    CustomAddButton,
    TableActions,
  },
  props: {
    capacites: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    disabledCheckbox: true,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    showGoals: false,
    newCap: {
      niveau: "",
      name: "",
      checked: null,
    },
    loading: true,
    tableFields: [
      { key: "name", label: "Capacité SI", sortable: true },
      { key: "niveau", label: "Niveau", sortable: true },
      { key: "isPrincipal", label: "Principale", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
  }),
  validations: {
    newCap: {
      name: {
        required,
        minLength: minLength(5),
      },
      niveau: {
        required,
      },
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationCapacites", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
    this.$store.dispatch("capaciteSi/fetchAllCapacites");
  },
  computed: {
    ...mapGetters("application", ["CAPACITES"]),
    ...mapGetters("capaciteSi", ["CAPACITES_SI"]),

    rows() {
      return this.tableItems.length;
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    capacitesList() {
      var capacites = this.CAPACITES_SI.filter(
        (capacite) => capacite.niveau === 2
      ); // select only the second level

      // group by parent
      var capacitesTable = [];
      Object.keys(groupBy(capacites, "parent.id")).map((label) => {
        var parent = groupBy(capacites, "parent.id")[label][0].parent;
        //
        capacitesTable.push({
          fullLabel: `${parent.name}`,
          ...parent,
          selectable: false,
        });
        //
        groupBy(capacites, "parent.id")[label].map((capacite) => {
          capacitesTable.push({
            fullLabel: `${parent.name}_${capacite.name}`,
            ...capacite,
            selectable: true,
          });
          var sousCapacites = uniqBy(capacite.sousCapacites, "id");
          sousCapacites.map((sCapacite) => {
            capacitesTable.push({
              fullLabel: `${parent.name}_${capacite.name}_${sCapacite.name}`,
              ...sCapacite,
              selectable: true,
            });
          });
        });
      });
      return capacitesTable
        .filter(
          (capacite) =>
            !this.CAPACITES.capaciteSis ||
            !this.CAPACITES.capaciteSis.find(
              (capSi) => capSi.id === capacite.id
            )
        )
        .some((capacite) => capacite.selectable)
        ? capacitesTable.filter(
            (capacite) =>
              !this.CAPACITES.capaciteSis ||
              !this.CAPACITES.capaciteSis.find(
                (capSi) => capSi.id === capacite.id
              )
          )
        : [];
    },
  },
  methods: {
    getLevel() {
      this.newCap.niveau = this.newCap.name ? this.newCap.name.niveau : "";
    },
    editItem(data) {
      this.$store
        .dispatch(
          "application/fetchApplicationCapacites",
          this.$route.params.id
        )
        .then(() => {
          this.newCap.name = data.name;
          this.newCap.niveau = data.niveau;
          this.newCap.checked = data.isPrincipal;
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === data.id
              ? {
                  ...challenge,
                  editMode: true,
                  Modify: true,
                }
              : challenge;
          });
        });
    },
    cancelItem(item) {
      if (!item.id)
        if (this.newCap.name === "" && this.newCap.niveau === "") {
          var test = this.tableItems.find(
            (contributer) => contributer.Modify == true
          );
          if (test == null) {
            this.tableItems.splice(0, 1);
          } else {
            this.$store
              .dispatch(
                "application/fetchApplicationCapacites",
                this.$route.params.id
              )
              .then(() => {
                this.$v.$reset();
              });
          }
        } else (this.newCap.name = ""), (this.newCap.niveau = "");
      else {
        this.$store
          .dispatch(
            "application/fetchApplicationCapacites",
            this.$route.params.id
          )
          .then(() => {
            this.$v.$reset();
          });
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          let capacite = this.CAPACITES_SI.find(({ id }) => id === item.id);
          window.console.log(capacite);
          let applications = capacite
            ? capacite.applications
                .map((app) => app["@id"])
                .filter(
                  (app) =>
                    app != `/api/${APPLICATION_URL}/${this.$route.params.id}`
                )
            : [];
          window.console.log(applications);
          this.$store
            .dispatch("capaciteSi/updateCapacite", {
              id: item.id,
              applications: applications,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationCapacites",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                  this.$v.$reset();
                  (this.newCap.name = ""), (this.newCap.niveau = "");
                });
            });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (item.id) {
          this.$store
            .dispatch("capaciteSi/updateCapacite", {
              id: item.id,
              niveau: parseInt(this.newCap.niveau),
              isPrincipal: this.newCap.checked ? true : false,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationCapacites",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("La capacité est bien créée !", "", "success");
                  (this.newCap.name = ""), (this.newCap.niveau = "");
                  this.$v.$reset();
                });
            });
        } else {
          var applications = this.newCap.name.applications.map(
            (application) => application["@id"]
          );
          APPLICATION_URL;
          applications.push(`/api/${APPLICATION_URL}/${this.$route.params.id}`);
          this.$store
            .dispatch("capaciteSi/updateCapacite", {
              id: this.newCap.name.id,
              applications: applications,
              niveau: parseInt(this.newCap.niveau),
              isPrincipal: this.newCap.checked ? true : false,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationCapacites",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("La capacité est bien créée !", "", "success");
                  (this.newCap.name = ""), (this.newCap.niveau = "");
                  this.$v.$reset();
                });
            });
        }
      }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
  },
  watch: {
    CAPACITES() {
      this.tableItems = this.CAPACITES.capaciteSis;
    },
  },
};
</script>

<style></style>
