<template>
  <div>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des bénéficiaires
      </span>

      <div class="d-flex justify-content-end">
        <custom-add-button
          :disabled="disabled"
          @click="addItem"
          text="Ajouter un bénéficiaire"
        ></custom-add-button>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem"
              @confirmItem="confirmItem(data.item.id)"
            />
          </template>
          <template v-slot:cell(type)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                appendToBody
                type="text"
                v-model="newBen.type"
                placeholder="Type bénéficiaire"
                :options="BENEFICIAIRE_TYPES"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newBen.type.$error && $v.newBen.type.$dirty,
                }"
                @input="
                  (newBen.name = ''),
                    (newBen.organisation = newBen.type
                      ? newBen.type === 'Externe'
                        ? 'Aucune'
                        : null
                      : null)
                "
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBen.type.$dirty"
              >
                {{ !$v.newBen.type.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name: 'show-payee',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'payee',
                },
              }"
            >
              {{ data.value }}
            </b-link>
            <div v-else>
              <v-select
                type="text"
                placeholder="Bénéficiaires"
                appendToBody
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newBen.name.$error && $v.newBen.name.$dirty,
                }"
                :options="beneficairesList"
                v-model="newBen.name"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBen.name.$dirty"
              >
                {{
                  !$v.newBen.name.required
                    ? "Champ obligatoire"
                    : !$v.newBen.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newBen.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
            </div>
          </template>

          <template v-slot:cell(organisation)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value ? data.value.libelle : "Aucune" }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                label="text"
                :reduce="(org) => org.value"
                type="text"
                :options="organisationsList"
                placeholder="Organisation"
                v-model="newBen.organisation"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newBen.organisation.$error &&
                    $v.newBen.organisation.$dirty,
                }"
                :disabled="newBen.type === 'Externe'"
              >
                <template #no-options> Liste vide </template>
              </v-select>

              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBen.organisation.$dirty"
              >
                {{
                  !$v.newBen.organisation.required ? "Champ obligatoire" : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(volume)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                type="number"
                placeholder="Volume"
                min="0"
                :class="{
                  'is-invalid':
                    $v.newBen.volume.$error && $v.newBen.volume.$dirty,
                }"
                v-model="newBen.volume"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBen.volume.$dirty"
              >
                {{ !$v.newBen.volume.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { APPLICATION_URL } from "@/helpers/constURL";

import {
  minLength,
  required,
  numeric,
  minValue,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  components: { CustomAddButton, TableActions },
  props: {
    beneificiaires: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    newBen: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      organisation: {
        required: requiredIf(function (/*val*/) {
          return !this.isOptional;
        }),
      },
      volume: {
        required,
        numeric,
        minValue: minValue(0),
      },
    },
  },
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    showGoals: false,
    newBen: {
      type: "",
      name: "",
      organisation: null,
      volume: null,
    },
    tableFields: [
      { key: "type", label: "Type bénéficiaire", sortable: true },
      { key: "name", label: "Bénéficiaires", sortable: true },
      { key: "organisation", label: "Organisation", sortable: true },
      { key: "volume", label: "Volume", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
  }),
  created() {
    this.$store
      .dispatch(
        "application/fetchApplicationBeneficaires",
        this.$route.params.id
      )
      .then(() => {
        this.loading = false;
      });

    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchBeneficiaireTypes");
  },
  computed: {
    ...mapGetters("application", ["BENEFICAIRES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("type", ["BENEFICIAIRE_TYPES"]),

    rows() {
      return this.tableItems.length;
    },
    organisationsList() {
      // Si type bénéficiaire = Interne, alors liste déroulante=
      // - Liste des organisation (Direction de niveau 1 et 2 uniquement)
      if (this.newBen.type === "Interne")
        return this.ORGANISATIONS.filter(
          (organisation) =>
            organisation.type === "Direction" &&
            (organisation.niveau === 2 || organisation.niveau === 1)
        ).map((organisation) => ({
          value: organisation["@id"],
          text: organisation.libelle,
        }));
      //Si type bénéficiaire = Externe, alors liste déroulante=
      // - Aucune
      else if (this.newBen.type === "Externe")
        return [{ value: null, text: "Aucune" }];
      else return [];
    },
    beneficairesList() {
      //Si type bénéficiaire = Externe alors Liste déroulante =
      // 1- Client
      // 2- Partenaires
      // 3- Prestataires
      if (this.newBen.type === "Externe")
        return ["Client", "Partenaires", "Prestataires"];
      // Si type bénéficiaire = Interne alors Liste déroulante =
      // 4- Collaborateurs
      else if (this.newBen.type === "Interne") return ["Collaborateurs"];
      // Si type bénéficiaire = Autre alors Liste déroulante =
      return [];
    },
    isOptional() {
      return this.newBen.type === "Externe"; // some conditional logic here...
    },
  },
  methods: {
    editItem(data) {
      this.$store
        .dispatch(
          "application/fetchApplicationBeneficaires",
          this.$route.params.id
        )
        .then(() => {
          this.newBen.name = data.name;
          this.newBen.type = data.type;
          this.newBen.volume = data.volume;
          this.newBen.organisation = data.organisation
            ? data.organisation["@id"]
            : null;
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === data.id
              ? {
                  ...challenge,
                  editMode: true,
                  Modify: true,
                }
              : challenge;
          });
        });
    },
    cancelItem() {
      if (
        this.newBen.name === "" &&
        this.newBen.type === "" &&
        this.newBen.organisation === "" &&
        this.newBen.volume === null
      ) {
        var test = this.tableItems.find(
          (contributer) => contributer.Modify == true
        );
        if (test == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch(
            "application/fetchApplicationBeneficaires",
            this.$route.params.id
          );
        }
      } else
        (this.newBen.name = ""),
          (this.newBen.type = ""),
          (this.newBen.organisation = ""),
          (this.newBen.volume = null);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("beneficiare/deleteBeneficiare", id).then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationBeneficaires",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
                this.$v.$reset();
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (item)
          this.$store
            .dispatch("beneficiare/updateBeneficiare", {
              id: item,
              name: this.newBen.name,
              type: this.newBen.type,
              organisation:
                this.newBen.organisation === "Aucune"
                  ? null
                  : this.newBen.organisation,
              volume: parseInt(this.newBen.volume),
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationBeneficaires",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Le bénéficiaire est mis à jour !", "", "success");
                  this.newBen = {
                    type: "",
                    name: "",
                    organisation: null,
                    volume: null,
                  };
                  this.$v.$reset();
                });
            });
        else
          this.$store
            .dispatch("beneficiare/createBeneficiare", {
              applications: [
                `/api/${APPLICATION_URL}/${this.$route.params.id}`,
              ],
              name: this.newBen.name,
              type: this.newBen.type,
              organisation:
                this.newBen.organisation === "Aucune"
                  ? null
                  : this.newBen.organisation,
              volume: parseInt(this.newBen.volume),
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationBeneficaires",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Le bénéficiaire est bien créé !", "", "success");
                  this.newBen = {
                    type: "",
                    name: "",
                    organisation: null,
                    volume: null,
                  };
                  this.$v.$reset();
                });
            });
      }
      //  }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
        this.$v.$reset();
      }
    },
  },
  watch: {
    BENEFICAIRES() {
      this.tableItems = this.BENEFICAIRES.beneficiaires.map((bene) => ({
        ...bene,
        organisation: bene.organisation || null,
        volume: bene.volume || 0,
      }));
    },
  },
};
</script> 