<template>
  <div>
    <div class="row mb-2">
      <div class="col-12 d-flex justify-content-end"></div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-tabs
          :value="$store.state.tabs.dataApplicationTab"
          @changed="dataApplicationTab = $store.state.tabs.dataApplicationTab"
          @activate-tab="
            (newTabIndex) =>
              $store.dispatch('tabs/changeDataApplicationTab', newTabIndex)
          "
          class="tab-solid tab-solid-primary mt-3"
        >
          <b-tab title="Traitement GDPR">
            <ApplicationTraitement />
          </b-tab>

          <b-tab title="Objets métier manipulés">
            <ApplicationObject />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationTraitement from "./ApplicationTraitement.vue";
import ApplicationObject from "./ApplicationObject.vue";
export default {
  components: { ApplicationTraitement, ApplicationObject },
  data: () => ({
    showGoals: false,
  }),
};
</script>

<style></style>
