var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mb-2"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des composants techniques ")]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('CustomAddButton',{attrs:{"disabled":_vm.disabled || (!_vm.composantTypes.length ? true : false),"text":"Ajouter un composant tech.\n"},on:{"click":_vm.addItem}})],1)]),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.tableFields,"responsive":"","bordered":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"striped":"","show-empty":"","busy":_vm.loading},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"cell(type)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_c('v-select',{class:{
                  'is-invalid form-control p-0':
                    _vm.$v.type.$error && _vm.$v.type.$dirty,
                },staticStyle:{"padding":"0 !important"},attrs:{"appendToBody":"","options":_vm.composantTypes,"type":"text","value":data.value,"placeholder":"Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(_vm.$v.type.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.type.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(versions)",fn:function(data){return [(data.value.length)?_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""},on:{"click":function($event){return _vm.showVersions(data.item)}}},[_vm._v(" "+_vm._s(data.value.length)+" ")]):_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(" "+_vm._s(data.value.length)+" ")]),_c('span')]}},{key:"cell(name)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-link',{attrs:{"to":{
                name: 'show-composant',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_c('v-select',{class:{
                  'is-invalid form-control p-0':
                    _vm.$v.composant.$error && _vm.$v.composant.$dirty,
                },staticStyle:{"padding":"0 !important"},attrs:{"label":"name","placeholder":"Composant technique","appendToBody":"","options":_vm.composantsList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.composant),callback:function ($$v) {_vm.composant=$$v},expression:"composant"}}),(_vm.$v.composant.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.composant.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":_vm.disabled ? [] : ['delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"editItem":function($event){return _vm.editItem(data.index)},"deleteItem":function($event){return _vm.deleteItem(data.item)},"cancelItem":function($event){return _vm.cancelItem(data.item)},"confirmItem":function($event){return _vm.confirmItem(data.item)}}})]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),(_vm.showVersionsModal)?_c('div',{attrs:{"to":"modals-xyz-500"}},[_c('modal',{on:{"close":function($event){_vm.showVersionsModal = !_vm.showVersionsModal}}},[_c('VersionList',{attrs:{"composant":_vm.composant}})],1)],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }