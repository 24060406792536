<template>
  <div>
    <p
      class="text-secondary my-auto mr-auto"
      style="margin-top: -56px !important"
    >
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Application :
      </span>
      <span style="font-size: 22px">{{ APPLICATION.name }}</span>
    </p>
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche Cadre:</span>
      <b-form-input placeholder="Cadre" v-model="searchQuery.cadre" />
      <b-button
        class="ml-1"
        @click="cofirmCadre(selectedCadre)"
        :disabled="selectedCadre === null"
        type=""
      >
        Confirmer
      </b-button>
    </div>
    <!-- {{existedCadres}} -->
    <b-table
      :fields="fields"
      :items="filteredCadres"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedCadre"
            :value="data.item"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(name)="data">
        <span> {{ data.value }}</span>
      </template>
      <template #cell(principe)="data">
        <span> {{ data.value ? data.value.name : "-" }}</span>
      </template>
      <template #cell(organisation)="data">
        <span> {{ data.value ? data.value : "-" }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { APPLICATION_URL } from "@/helpers/constURL";
import Swal from "sweetalert2";

export default {
  props: {
    libelle: {
      type: String,
      default: "",
    },

    existedCadres: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedCadre: null,
      selectedUser: null,
      loadingData: true,
      searchQuery: {
        cadre: "",
      },
      fields: [
        { key: "name", label: "Cadre" },
        { key: "principe", label: "Principe" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    cofirmCadre() {
      // this.$emit("confirm-cadre", cadre);
      window.console.log(this.selectedCadre);
      this.$store
        .dispatch("cadre/updateCadre", {
          id: this.selectedCadre.id,
          applications: [`/api/${APPLICATION_URL}/${this.$route.params.id}`],
        })
        .then(() => {
          this.$store
            .dispatch(
              "application/fetchApplicationCadres",
              this.$route.params.id
            )
            .then(() => {
              Swal.fire("Le cadre est bien créé !", "", "success");
              this.selectedCadre = null;
              this.$emit("close");
            });
        });
    },
  },
  created() {
    this.$store.dispatch("cadre/fetchAllCadres").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    ...mapGetters("application", ["APPLICATION"]),
    filteredCadres() {
      return this.CADRE_ENTREPRISES
        ? this.CADRE_ENTREPRISES.filter(
            (cadre) =>
              !this.APPLICATION.cadreEntreprises.find(
                (cEentr) => cEentr["@id"] === cadre["@id"]
              )
          )
            .filter(
              ({ name }) =>
                name
                  .toLowerCase()
                  .indexOf(this.searchQuery.cadre.toLowerCase()) > -1
            )
            .filter(
              (cadre) =>
                !this.existedCadres.find((c) =>
                  c.id ? c.id === cadre.id : false
                )
            )
        : [];
    },
    rows() {
      return this.filteredCadres.length;
    },
  },
};
</script>

<style></style>
