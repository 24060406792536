<template>
  <div class="form-group" style="margin-top: 4%">
    <label for="paysResponable"></label>
    <input
      type="text"
      class="form-control"
      id="paysResponable"
      autocomplete="off"
      placeholder="Choisissez les couvertures"
      style="width: 100%"
    />
    <input type="text" hidden="hidden" id="postal_code" />
    <input type="text" hidden="hidden" id="lat" />
    <input type="text" hidden="hidden" id="lng" />
    <input type="text" hidden="hidden" id="country_code" />
    <input type="text" hidden="hidden" id="country" />
    <input type="text" hidden="hidden" id="city" />
  </div>
</template>

<script>
import Places from "places.js";
export default {
  props: {
    paysResponsableFunction: {
      type: Function,
    },
  },
  data: () => ({
    editMode: false,
    coverture: "",
    location: {
      adresse: "",
      country: "",
      lat: "",
      lng: "",
      countryCode: "",
    },
  }),

  mounted: function () {
    let inputAddress = document.querySelector("#paysResponable");

    if (inputAddress !== null) {
      let place = Places({
        container: inputAddress,
        language: "fr",
        type: "country",

        aroundLatLngViaIP: false,
      });
      place.on("change", (e) => {
        if (e.suggestion.type === "country") {
          this.location.adresse = e.suggestion.name;
        } else {
          this.location.city = e.suggestion.city;
        }
        this.location.lat = Number.parseFloat(e.suggestion.latlng.lat);
        this.location.lng = Number.parseFloat(e.suggestion.latlng.lng);
        this.location.country = e.suggestion.name;
        this.location.countryCode = e.suggestion.countryCode;
        this.paysResponsableFunction(this.location);
        inputAddress.value = "";
      });
    }
  },
};
</script>

<style></style>
