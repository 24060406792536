<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des Flux et échanges</span
      >

      <div class="row mb-0">
        <div class="col-12 d-flex justify-content-end">
          <custom-add-button
            :disabled="disabled"
            @click="addItem"
            text="Ajouter un flux et échanges"
          ></custom-add-button>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{ FLUXS }} -->
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(sens)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                v-model="newFluxe.sens"
                :options="['Envoi']"
                placeholder="Sens de flux"
                :value="data.value"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.sens.$error && $v.newFluxe.sens.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.sens.$dirty"
              >
                {{ !$v.newFluxe.sens.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(destinataire)="data">
            <b-link
              :to="{
                name: 'show-application',
                params: {
                  id: data.value.id,
                  slug: data.value.slug,
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              {{ data.value.name }}
            </b-link>
            <div v-else>
              <v-select
                append-to-body
                label="name"
                v-model="newFluxe.destinataire"
                placeholder="Destinataire"
                :options="applicationsList"
                :value="data.value"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.destinataire.$error &&
                    $v.newFluxe.destinataire.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.destinataire.$dirty"
              >
                {{
                  !$v.newFluxe.destinataire.required ? "champ obligatoire" : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(description)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                type="text"
                placeholder="Description"
                v-model="newFluxe.description"
                :value="data.value"
                :class="{
                  'is-invalid':
                    $v.newFluxe.description.$error &&
                    $v.newFluxe.description.$dirty,
                }"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.description.$dirty"
              >
                {{
                  !$v.newFluxe.description.required ? "champ obligatoire" : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(frequence)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                :options="[
                  'Quotidien',
                  'Hebdo',
                  'Mensuel',
                  'Annuel',
                  'Ponctuel',
                ]"
                placeholder="frequence"
                v-model="newFluxe.frequence"
                :value="data.value"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.frequence.$error &&
                    $v.newFluxe.frequence.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.frequence.$dirty"
              >
                {{ !$v.newFluxe.frequence.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(technoEchange)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <span v-if="data.item.isManuel"> Manuel </span>

              <b-link
                v-else-if="data.value"
                :to="{
                  name: 'show-composant',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug,
                  },
                }"
              >
                {{ data.value ? data.value.name : "-" }}
              </b-link>
              <span v-else> -</span>
            </span>
            <div v-else>
              <v-select
                append-to-body
                label="name"
                :reduce="(item) => item['@id']"
                :options="composantsList"
                placeholder="Techno. échange"
                v-model="newFluxe.technoEchange"
                :value="data.value"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.technoEchange.$error &&
                    $v.newFluxe.technoEchange.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.technoEchange.$dirty"
              >
                {{
                  !$v.newFluxe.technoEchange.required ? "champ obligatoire" : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(protocol)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                :options="['SOAP', 'REST', 'SFTP', 'Autre']"
                :value="data.value"
                placeholder="Protocole"
                v-model="newFluxe.protocol"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.protocol.$error && $v.newFluxe.protocol.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.protocol.$dirty"
              >
                {{ !$v.newFluxe.protocol.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(format)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                :options="['Excel', 'Json', 'CSV', 'XML', 'Autre']"
                :value="data.value"
                placeholder="Format"
                v-model="newFluxe.format"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.format.$error && $v.newFluxe.format.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.format.$dirty"
              >
                {{ !$v.newFluxe.format.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(certificat)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                :options="['Aucun', 'SSL', 'TLS', 'Autre']"
                placeholder="Certificat"
                v-model="newFluxe.certificat"
                :value="data.value"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFluxe.certificat.$error &&
                    $v.newFluxe.certificat.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.certificat.$dirty"
              >
                {{
                  !$v.newFluxe.certificat.required ? "champ obligatoire" : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(objet)="data">
            <div
              class="text-center"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                @click="objectList(data.item)"
                variant="secondary"
                >{{ data.value ? data.value.length : 0 }}
              </b-badge>
            </div>
            <div class="text-center" v-else style="">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
              >
                {{ selectedObjects.length }}
              </b-badge>
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                @click="showObjectModal = true"
                >+
              </b-badge>
            </div>
          </template>
          <template v-slot:cell(volume)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                type="number"
                min="0"
                :value="data.value"
                placeholder="Volume"
                v-model="newFluxe.volume"
                :class="{
                  'is-invalid ':
                    $v.newFluxe.volume.$error && $v.newFluxe.volume.$dirty,
                }"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFluxe.volume.$dirty"
              >
                {{ !$v.newFluxe.volume.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem"
              @confirmItem="confirmItem(data.item)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <!-- {{ selectedObjects }} -->
    <div v-if="showObjectModal" to="modals-xyz-548">
      <modal @close="showObjectModal = !showObjectModal">
        <ObjectsList
          @close="showObjectModal = !showObjectModal"
          :selected="selectedObjects"
          @confirmObjects="confirmObjects"
        />
      </modal>
    </div>
    <div v-if="showFonctionList" to="modals-xyz-548">
      <modal @close="showFonctionList = !showFonctionList">
        <FluxObjectsList
          @close="showFonctionList = !showFonctionList"
          :fluxId="selectedFlux"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";
import Swal from "sweetalert2";
import { APPLICATION_URL } from "@/helpers/constURL";
import { mapGetters } from "vuex";
import ObjectsList from "./ObjectsList";
import FluxObjectsList from "./FluxObjectsList";

import Modal from "@/components/custom/Modal.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    CustomAddButton,
    TableActions,
    ObjectsList,
    Modal,
    FluxObjectsList,
  },
  props: {
    fluxs: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    showGoals: false,
    showObjectModal: false,
    tableItems: [],
    list: [],
    newFluxe: {
      sens: "",
      destinataire: "",
      description: "",
      frequence: "",
      technoEchange: "",
      protocol: "",
      format: "",
      certificat: "",
      objet: "",
      volume: "",
      source: "",
    },
    tableFields: [
      {
        key: "sens",
        label: "Sens du flux",
        sortable: true,
      },
      { key: "destinataire", label: "Destinataire", sortable: true },
      { key: "description", label: "Description flux", sortable: true },
      { key: "frequence", label: "Fréquence", sortable: true },
      { key: "technoEchange", label: "Techno. échange", sortable: true },
      { key: "protocol", label: "Protocole", sortable: true },
      { key: "format", label: "Format", sortable: true },
      { key: "certificat", label: "Certificat", sortable: true },
      {
        key: "objet",
        label: "Objets métier échanges",
        sortable: true,
      },
      { key: "volume", label: "Volume de données", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    selectedObjects: [],
    showFonctionList: false,
    selectedFlux: null,
  }),

  validations: {
    newFluxe: {
      sens: { required },
      destinataire: { required },
      description: { required },
      frequence: { required },
      technoEchange: { required },
      protocol: { required },
      format: { required },
      certificat: { required },
      // objet: { required },
      volume: { required },
      // source: { required },
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch("application/fetchApplicationFluxs", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
      this.$store.dispatch("capaciteSi/fetchAllCapacites");
      this.$store.dispatch("application/fetchAllApplications");
      this.$store.dispatch("objet/fetchAllObjets");
      this.$store.dispatch("composant/fetchAllComposants");
    } else this.loading = false;
  },
  computed: {
    ...mapGetters("application", ["FLUXS", "APPLICATIONS"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    applicationsList() {
      return this.APPLICATIONS.filter(
        ({ id }) => parseInt(id) !== parseInt(this.$route.params.id)
      );
    },
    composantsList() {
      let composants = this.COMPOSANTS;
      composants.unshift({ "@id": 0, name: "Manuel" });
      return this.COMPOSANTS;
    },
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    confirmObjects(objects) {
      this.selectedObjects = objects;
    },
    objectList(item) {
      if (item.objet.length) {
        this.selectedFlux = item.id;
        this.showFonctionList = !this.showFonctionList;
        window.console.log(item);
      }
    },
    editItem(item) {
      this.$store
        .dispatch(
          "application/fetchApplicationBeneficaires",
          this.$route.params.id
        )
        .then(() => {
          this.newFluxe = item;
          this.selectedObjects = item.objet;
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === item.id
              ? {
                  ...challenge,
                  editMode: true,
                  Modify: true,
                }
              : challenge;
          });
        });
    },
    cancelItem() {
      if (
        this.newFluxe.sens === "" &&
        this.newFluxe.destinataire === "" &&
        this.newFluxe.description === "" &&
        this.newFluxe.frequence === "" &&
        this.newFluxe.technoEchange === "" &&
        this.newFluxe.protocol === "" &&
        this.newFluxe.format === "" &&
        this.newFluxe.objet === "" &&
        this.newFluxe.volume === "" &&
        this.newFluxe.source === "" &&
        this.newFluxe.certificat === "" &&
        this.selectedObjects.length === 0
      ) {
        var test = this.tableItems.find(
          (contributer) => contributer.Modify == true
        );
        if (test == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store
            .dispatch(
              "application/fetchApplicationFluxs",
              this.$route.params.id
            )
            .then(() => {
              this.$v.$reset();
            });
        }
      } else {
        this.newFluxe.sens = "";
        this.newFluxe.destinataire = "";
        this.newFluxe.description = "";
        this.newFluxe.frequence = "";
        this.newFluxe.technoEchange = "";
        this.newFluxe.protocol = "";
        this.newFluxe.format = "";
        this.newFluxe.objet = "";
        this.newFluxe.volume = "";
        this.newFluxe.source = "";
        this.newFluxe.certificat = "";
        this.selectedObjects = [];
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("fluxe/deleteFluxe", id).then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationFluxs",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
                this.$v.$reset();
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      // window.console.log(this.newFluxe);

      if (!this.$v.$invalid) {
        // declare new fluxe variable to store the new fluxe
        var newFluxe = {
          sens: this.newFluxe.sens,
          destinataire: this.newFluxe.destinataire["@id"],
          description: this.newFluxe.description,
          frequence: this.newFluxe.frequence,
          protocol: this.newFluxe.protocol,
          format: this.newFluxe.format,
          certificat: this.newFluxe.certificat,
          objet: this.selectedObjects,
          volume: parseInt(this.newFluxe.volume),
          source: `/api/${APPLICATION_URL}/${this.$route.params.id}`,
        };

        newFluxe = this.newFluxe.technoEchange
          ? {
              // if the user has selected a technoEchange
              ...newFluxe,
              technoEchange:
                typeof this.newFluxe.technoEchange === "object"
                  ? this.newFluxe.technoEchange["@id"]
                  : this.newFluxe.technoEchange, // add the technoEchange to the newFluxe
              isManuel: false, // set the isManuel property to true
            }
          : {
              // if the user hasn't selected a technoEchange
              ...newFluxe,
              isManuel: true, // set the isManuel property to true
            };
        if (item.id)
          newFluxe = {
            // if the user is editing an existing fluxe
            ...newFluxe,
            id: item.id, // add the id to the newFluxe
          };

        this.$store
          .dispatch(`fluxe/${item.id ? "update" : "create"}Fluxe`, newFluxe)
          .then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationFluxs",
                this.$route.params.id
              )
              .then(() => {
                item.id
                  ? Swal.fire("Le flux est mis à jour !", "", "success")
                  : Swal.fire("Le flux est bien créé !", "", "success");

                this.$v.$reset();
                this.cancelItem();
              });
          });
      }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        window.console.log("899865");
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
  },
  watch: {
    FLUXS() {
      this.tableItems = this.FLUXS.map((flux) => ({
        ...flux,
        technoEchange: flux.isManuel ? 0 : flux.technoEchange,
      }));
    },
  },
};
</script>

<style></style>
