<template>
  <div
    class="
      w-100
      p-1
      col-12 col-sm-6 col-md-2
      p-0
      flex-column
      align-items-center
      justify-content-center
    "
    style="height: 240px"
  >
    <div class="shdow p-3 h-100 w-100">
      <div class="text-center">
        <p style="text-align: center">{{ role }}</p>
      </div>
      <div
        style="
          background: black;
          height: 100px;
          width: 100px;
          border-radius: 50px;
          position: relative;
        "
        class="
          accnt-log
          d-flex
          mx-auto
          align-items-center
          justify-content-center
        "
      >
        <i
          v-if="!user || !user.avatarUrl"
          style="color: white; font-size: 40px"
          class="mdi mdi-account-outline"
        ></i>
        <img
          v-else
          class="rounded-pill"
          style="width: 96%; height: 96%; object-fit: cover"
          :src="IMG_URL + user.avatarUrl"
          :alt="`${user.firstname} ${user.lastname}`"
        />
        <div style="position: absolute; bottom: -8px; right: 5px">
          <i
            v-if="user === null"
            @click="$emit('setUserRole', index, role)"
            style="
              color: blue;
              font-size: 25px;
              background: white;
              border-radius: 15px;
              cursor: pointer;
            "
            class="mdi mdi-plus-circle"
          ></i>
          <i
            v-else
            @click="$emit('deleteUser', index, referentId)"
            style="
              color: red;
              font-size: 25px;
              background: white;
              border-radius: 15px;
              cursor: pointer;
            "
            class="mdi mdi-close-circle"
          ></i>
        </div>
      </div>
      <div>
        <p class="font-weight-bold my-2 text-center" v-if="user">
          {{ `${user.firstname} ${user.lastname}` }}
        </p>
        <p class="my-2 text-center" v-else>Aucun collaborateur</p>
      </div>
    </div>
  </div>
</template>

<script>
import { IMG_URL } from "@/helpers/services/api";
export default {
  props: {
    user: {
      type: Object,
    },
    role: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    referentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      IMG_URL,
    };
  },
};
</script>
