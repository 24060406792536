<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto">Liste des APIs </span>

      <div class="row mb-0">
        <div class="col-12 d-flex justify-content-end">
          <custom-add-button
            :disabled="disabled"
            text="Ajouter une API"
            @click="addItem"
          ></custom-add-button>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          bordered
          striped
          show-empty
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <b-form-input
                type="text"
                placeholder="API"
                :class="{
                  'is-invalid': $v.newApi.name.$error && $v.newApi.name.$dirty,
                }"
                v-model="newApi.name"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newApi.name.$dirty"
              >
                {{
                  !$v.newApi.name.required
                    ? "champ obligatoire"
                    : !$v.newApi.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newApi.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(description)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <b-form-input
              type="text"
              placeholder="Description API"
              v-model="newApi.description"
              v-else
            >
            </b-form-input>
          </template>
          <template v-slot:cell(fonctions)="data">
            <div
              class="text-center"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                @click="FonctionList(data.item)"
                variant="secondary"
                >{{ data.value ? data.value.length : 0 }}
              </b-badge>
            </div>
            <div class="text-center" v-else style="">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                @click="Fonctionselect(data.item)"
                >{{ list.length == 0 ? "+" : list.length }}
              </b-badge>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem"
              @confirmItem="confirmItem(data.item.id)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <div v-if="showFonction" to="modals-xyz-548">
      <modal @close="showFonction = !showFonction">
        <FonctionList
          :libelle="libelle"
          @confirmfonction="confirmFonction"
          :selected="list"
          @close="showFonction = !showFonction"
        ></FonctionList>
      </modal>
    </div>
    <div v-if="showFonctionList" to="modals-xyz-548">
      <modal @close="showFonctionList = !showFonctionList">
        <Fonction :libelle="name" :items="data" :name="libelle"></Fonction>
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import Modal from "@/components/custom/Modal.vue";
import { minLength, required } from "vuelidate/lib/validators";
import FonctionList from "./FonctionList";
import Fonction from "./Fonction";
import { APPLICATION_URL } from "@/helpers/constURL";

export default {
  components: { CustomAddButton, TableActions, Modal, FonctionList, Fonction },
  props: {
    apis: Array,
    libelle: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showFonction: false,
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    showGoals: false,
    tableFields: [
      { key: "name", label: "API", sortable: true },
      { key: "description", label: "Description", sortable: true },
      // { key: "fonctions", label: "Fonctions", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
    newApi: {
      name: "",
      description: "",
    },
    list: [],
    data: [],
    showFonctionList: false,
    name: "",
  }),
  validations: {
    newApi: {
      name: {
        required,
        minLength: minLength(5),
      },
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationApi", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("application", ["API"]),

    rows() {
      return this.tableItems.length;
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
  },
  methods: {
    Fonctionselect() {
      this.showFonction = !this.showFonction;
      var test = this.tableItems.find(
        (contributer) => contributer.Modify == true
      );
      if (test) {
        this.list = this.list.map((data) => data["@id"]);
      }
    },
    FonctionList(data) {
      if (data.fonctions.length) {
        this.name = data.name;
        this.data = data.fonctions;
        this.showFonctionList = !this.showFonctionList;
      }
    },
    confirmFonction(data) {
      this.list = data;
    },
    editItem(data) {
      this.$store
        .dispatch("application/fetchApplicationApi", this.$route.params.id)
        .then(() => {
          this.newApi.name = data.name;
          this.newApi.description = data.description;
          this.list = data.fonctions ? data.fonctions : [];
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === data.id
              ? {
                  ...challenge,
                  editMode: true,
                  Modify: true,
                }
              : challenge;
          });
        });
    },
    cancelItem() {
      if (
        this.newApi.name === "" &&
        this.newApi.description === "" &&
        this.list.length == 0
      ) {
        var test = this.tableItems.find(
          (contributer) => contributer.Modify == true
        );
        if (test == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch(
            "application/fetchApplicationApi",
            this.$route.params.id
          );
        }
      } else
        (this.newApi.name = ""),
          (this.list = []),
          (this.newApi.description = "");
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Api/deleteApi", id).then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationApi",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (item)
          this.$store
            .dispatch("Api/updateApi", {
              id: item,
              name: this.newApi.name,
              description: this.newApi.description,
              // fonctions: this.list,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationApi",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'API est mise à jour !", "", "success");
                  this.newApi.name = this.newApi.description = "";
                  this.$v.$reset();
                });
            });
        else
          this.$store
            .dispatch("Api/createApi", {
              application: `/api/${APPLICATION_URL}/${this.$route.params.id}`,
              name: this.newApi.name,
              description: this.newApi.description,
              fonctions: this.list,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationApi",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'API est bien créée !", "", "success");
                  this.$v.$reset();
                });
            });
      }
      //  }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.newApi = {
          name: "",
          description: "",
        };
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
  },
  watch: {
    API() {
      this.tableItems = this.API.apiExposes;
    },
  },
};
</script>

<style></style>
