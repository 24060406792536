<template>
  <div class="m-0 col-12 p-0">
    <div class="col-12 d-flex p-0 justify-content-start">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des référents
      </span>

      <custom-add-button
        :disabled="disabled"
        @click="searchRoleModal = !searchRoleModal"
        text="Ajouter rôle"
      ></custom-add-button>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div>
      <!-- Search role -->
      <div to="modals-xyz-548" v-if="searchRoleModal">
        <Modal @close="searchRoleModal = !searchRoleModal">
          <search-role
            :libelle="libelle"
            @cofirmRole="addRole"
            @close="searchRoleModal = !searchRoleModal"
          />
        </Modal>
      </div>

      <!-- Search guests -->
      <div to="modals-xyz-548" v-if="searchGuestsModal">
        <Modal @close="searchGuestsModal = !searchGuestsModal">
          <SearchGuests
            :libelle="libelle"
            @close="searchGuestsModal = !searchGuestsModal"
            @cofirmUser="confirmUser"
          />
        </Modal>
      </div>

      <div v-if="!disabled" class="d-flex">
        <!--  {{ REFERENTS.referents }} -->
        <CustomReferentCarde
          v-for="(referent, index) in referentsList"
          :key="index"
          :index="index"
          :role="referent.role"
          :user="referent.user"
          :referentId="referent.id"
          @setUserRole="setUserRole"
          @deleteUser="deleteUser(referent.id)"
        />
        <!-- {{ referentsList }} -->
      </div>
      <div v-else class="col-12 d-flex">
        <div
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/custom/Modal.vue";
import searchRole from "./searchRole.vue";
import SearchGuests from "./SearchGuests.vue";
import { IMG_URL } from "@/helpers/services/api";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import CustomReferentCarde from "@/components/custom/CustomReferentCarde";
import { APPLICATION_URL, USER_URL } from "@/helpers/constURL";
export default {
  components: {
    CustomAddButton,
    searchRole,
    Modal,
    SearchGuests,
    CustomReferentCarde,
  },
  props: {
    referents: {
      type: Array,
      default: () => [],
    },
    responsables: Array,
    libelle: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      IMG_URL,
      searchRoleModal: false,
      searchGuestsModal: false,
      selectedReferent: null,
      referentsList: [],
      selectedRole: null,
    };
  },
  methods: {
    addRole(role) {
      this.referentsList.push({
        role: role,
        user: null,
      });
      this.selectedRole = role;
    },
    confirmUser(userId) {
      if (userId)
        this.$store
          .dispatch("referent/createReferent", {
            role: this.selectedRole ? this.selectedReferent : "Sponsor",
            user: `/api/${USER_URL}/${userId}`,
            application: `/api/${APPLICATION_URL}/${this.$route.params.id}`,
          })
          .then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationReferents",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire({
                  title: "Le référent est bien créé !",
                  type: "success",
                  confirmButtonText: "OK",
                });
              });
          });
    },
    setUserRole(index, selectedRole) {
      this.selectedReferent = index;

      this.role = selectedRole;
      this.searchGuestsModal = !this.searchGuestsModal;
    },
    deleteUser(referentId) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value && referentId) {
          this.$store
            .dispatch("referent/deleteReferent", referentId)
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationReferents",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationReferents", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("application", ["REFERENTS"]),
  },
  watch: {
    REFERENTS() {
      this.referentsList = this.REFERENTS.referents.length
        ? this.REFERENTS.referents
        : [
            {
              role: "Sponsor",
              user: null,
            },
          ];
    },
  },
};
</script>
