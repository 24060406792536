<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des Fonctions
      </span>

      <div class="row mb-0">
        <div class="col-12 d-flex justify-content-end">
          <custom-add-button
            :disabled="disabled"
            @click="addItem"
            text="Ajouter une fonction"
          ></custom-add-button>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="loading"
          responsive
          show-empty
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value ? data.value : "-" }}
            </span>
            <div v-else>
              <b-form-input
                type="text"
                placeholder="Fonctions"
                :class="{
                  'is-invalid':
                    $v.newFonctions.name.$error && $v.newFonctions.name.$dirty,
                }"
                v-model="newFonctions.name"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFonctions.name.$dirty"
              >
                {{
                  !$v.newFonctions.name.required
                    ? "champ obligatoire"
                    : !$v.newFonctions.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newFonctions.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(description)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <b-form-input
              type="text"
              placeholder="Description"
              v-model="newFonctions.description"
              v-else
            ></b-form-input>
          </template>
          <template v-slot:cell(entrepriseValeur)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value ? data.value : "-" }}
            </span>
            <div v-else>
              <v-select
                appendToBody
                label="text"
                :options="organisationsList"
                placeholder="Entreprise Valeur"
                v-model="newFonctions.entreprise"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFonctions.entreprise.$error &&
                    $v.newFonctions.entreprise.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFonctions.entreprise.$dirty"
              >
                {{
                  !$v.newFonctions.entreprise.required
                    ? "champ obligatoire"
                    : ""
                }}
              </span>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.item)"
              @deleteItem="deleteItem(data.item.id)"
              @cancelItem="cancelItem"
              @confirmItem="confirmItem(data.item.id)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { minLength, required } from "vuelidate/lib/validators";
import { APPLICATION_URL } from "@/helpers/constURL";

export default {
  components: { CustomAddButton, TableActions },
  props: {
    fonctions: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    showGoals: false,
    tableItems: [],
    loading: true,
    newFonctions: {
      name: "",
      description: "",
      entreprise: "",
    },
    tableFields: [
      { key: "name", label: "Fonctions", sortable: true },
      { key: "description", label: "Description", sortable: true },
      { key: "entrepriseValeur", label: "Entreprise Valeur", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
  }),
  validations: {
    newFonctions: {
      name: {
        required,
        minLength: minLength(5),
      },
      entreprise: {
        required,
      },
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationFonctions", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });

    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchBeneficiaireTypes");
  },
  computed: {
    ...mapGetters("application", ["FONCTIONS"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("type", ["BENEFICIAIRE_TYPES"]),

    rows() {
      return this.tableItems.length;
    },
    organisationsList() {
      return ["1", "2", "3", "4", "5"];
    },
  },
  methods: {
    editItem(data) {
      this.$store
        .dispatch(
          "application/fetchApplicationFonctions",
          this.$route.params.id
        )
        .then(() => {
          this.newFonctions.name = data.name;
          this.newFonctions.description = data.description;
          this.newFonctions.entreprise = data.entrepriseValeur;
          this.tableItems = this.tableItems.map((challenge) => {
            return challenge.id === data.id
              ? {
                  ...challenge,
                  editMode: true,
                  Modify: true,
                }
              : challenge;
          });
        });
    },
    cancelItem() {
      if (
        this.newFonctions.name === "" &&
        this.newFonctions.description === "" &&
        this.newFonctions.entreprise === ""
      ) {
        var test = this.tableItems.find(
          (contributer) => contributer.Modify == true
        );
        if (test == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch(
            "application/fetchApplicationFonctions",
            this.$route.params.id
          );
          this.$v.$reset();
        }
      } else
        (this.newFonctions.name = ""),
          (this.newFonctions.description = ""),
          (this.newFonctions.entreprise = "");
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("fonction/deleteFonction", id).then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationFonctions",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
                this.$v.$reset();
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (item)
          this.$store
            .dispatch("fonction/updateFonction", {
              id: item,
              name: this.newFonctions.name,
              entrepriseValeur: this.newFonctions.entreprise,
              description: this.newFonctions.description,
            })
            .then(() => {
              Swal.fire("La fonction est mise à jour !", "", "success");
              this.$v.$reset();
              this.$store
                .dispatch(
                  "application/fetchApplicationFonctions",
                  this.$route.params.id
                )
                .then(() => {
                  (this.newFonctions.name = ""),
                    (this.newFonctions.description = ""),
                    (this.newFonctions.entreprise = "");
                });
            });
        else
          this.$store
            .dispatch("fonction/createFonction", {
              application: `/api/${APPLICATION_URL}/${this.$route.params.id}`,
              name: this.newFonctions.name,
              entrepriseValeur: this.newFonctions.entreprise,
              description: this.newFonctions.description,
            })
            .then(() => {
              Swal.fire("La fonction est bien créée !", "", "success");
              this.$v.$reset();
              this.$store
                .dispatch(
                  "application/fetchApplicationFonctions",
                  this.$route.params.id
                )
                .then(() => {
                  (this.newFonctions.name = ""),
                    (this.newFonctions.description = ""),
                    (this.newFonctions.entreprise = "");
                });
            });
      }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
  },
  watch: {
    FONCTIONS() {
      this.tableItems = this.FONCTIONS.fonctions;
    },
  },
};
</script>

<style></style>
