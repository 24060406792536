<template>
  <div>
    <div v-if="false" class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des traitements
      </span>

      <div class="row mb-0">
        <div class="col-12 d-flex justify-content-end">
          <custom-add-button
            :disabled="disabled"
            text="Ajouter un traitement"
            @click="addItem"
          ></custom-add-button>
        </div>
      </div>
    </div>
    <div v-if="false" class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      :items="tableItems"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(name)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value ? data.value : "-" }}
        </span>
        <div v-else>
          <b-form-input
            type="text"
            placeholder="Traitement"
            :class="{
              'is-invalid':
                $v.newTraitement.name.$error && $v.newTraitement.name.$dirty,
            }"
            v-model="newTraitement.name"
          ></b-form-input>
          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newTraitement.name.$dirty"
          >
            {{
              !$v.newTraitement.name.required
                ? "champ obligatoire"
                : !$v.newTraitement.name.minLength
                ? `Le champ doit contenir au moins ${$v.newTraitement.name.$params.minLength.min} caractères.`
                : ""
            }}
          </span>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value ? data.value : "-" }}
        </span>
        <div v-else>
          <v-select
            appendToBody
            label="text"
            :options="['Non réalisé', 'En cours', 'En prod']"
            placeholder="Status"
            v-model="newTraitement.status"
            style="padding: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.newTraitement.status.$error &&
                $v.newTraitement.status.$dirty,
            }"
          >
            <template #no-options> Liste vide </template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newTraitement.status.$dirty"
          >
            {{ !$v.newTraitement.status.required ? "champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : ['edit', 'delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @editItem="editItem(data.item)"
          @deleteItem="deleteItem(data.item.id)"
          @cancelItem="cancelItem"
          @confirmItem="confirmItem(data.item)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { APPLICATION_URL } from "@/helpers/constURL";
import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  components: { TableActions, CustomAddButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    showGoals: false,
    loading: true,
    newTraitement: {
      name: "",
      status: "",
    },
    tableFields: [
      { key: "name", label: "Traitement", sortable: true },
      { key: "status", label: "Ëtat", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
  }),
  validations: {
    newTraitement: {
      name: {
        required,
        minLength: minLength(5),
      },
      status: {
        required,
      },
    },
  },
  methods: {
    addItem() {
      var data = this.tableItems.find(
        (traitement) => traitement.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
    editItem(data) {
      this.$store
        .dispatch(
          "application/fetchApplicationTraitements",
          this.$route.params.id
        )
        .then(() => {
          this.newTraitement.name = data.name;
          this.newTraitement.status = data.status;
          this.tableItems = this.tableItems.map((item) => {
            return item.id === data.id
              ? {
                  ...item,
                  editMode: true,
                  Modify: true,
                }
              : item;
          });
        });
    },
    cancelItem() {
      if (this.newTraitement.name === "" && this.newTraitement.status === "") {
        this.$store.dispatch(
          "application/fetchApplicationTraitements",
          this.$route.params.id
        );
      } else (this.newTraitement.name = ""), (this.newTraitement.status = "");
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("traitement/deleteTraitement", id).then(() => {
            this.$store
              .dispatch(
                "application/fetchApplicationTraitements",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (item.id)
          this.$store
            .dispatch("traitement/updateTraitement", {
              id: item.id,
              name: this.newTraitement.name,
              status: this.newTraitement.status,
            })
            .then(() => {
              Swal.fire("Le traitement est mise à jour !", "", "success");
              this.$store
                .dispatch(
                  "application/fetchApplicationTraitements",
                  this.$route.params.id
                )
                .then(() => {
                  (this.newTraitement.name = ""),
                    (this.newTraitement.status = "");
                });
            });
        else
          this.$store
            .dispatch("traitement/createTraitement", {
              application: `/api/${APPLICATION_URL}/${this.$route.params.id}`,
              name: this.newTraitement.name,
              status: this.newTraitement.status,
            })
            .then(() => {
              Swal.fire("Le traitement est bien créée !", "", "success");
              this.$store
                .dispatch(
                  "application/fetchApplicationTraitements",
                  this.$route.params.id
                )
                .then(() => {
                  (this.newTraitement.name = ""),
                    (this.newTraitement.status = "");
                });
            });
      }
    },
  },
  created() {
    this.$store
      .dispatch(
        "application/fetchApplicationTraitements",
        this.$route.params.id
      )
      .then(() => {
        this.loading = false;
      });

    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchBeneficiaireTypes");
  },
  computed: {
    ...mapGetters("application", ["TRAITEMENTS"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("type", ["BENEFICIAIRE_TYPES"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    TRAITEMENTS() {
      this.tableItems = this.TRAITEMENTS.traitements;
    },
  },
};
</script>
