<template>
  <div>
    <p
      class="text-secondary my-auto mr-auto"
      style="margin-top: -56px !important"
    >
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Application :
      </span>
      <span style="font-size: 22px">{{ APPLICATION.name }}</span>
    </p>
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">List des objets:</span>

      <!-- <button @click="$refs.objectsTable.clearSelected()">  fffff</button> -->
    </div>
    <div v-if="false" class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      :items="tableItems"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
      bordered
      striped
      ref="objectsTable"
      selectable
      select-mode="multi"
      @row-selected="onRowSelected"
      :busy="loading"
    >
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(business_object)="data">
        <b-link
          :to="{
            name: 'show-business-object',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.item ? data.item.name : "-" }}
        </b-link>
      </template>
      <template v-slot:cell(sensitivity)="data">
        <span>
          <b-badge :variant="sensible(data.item) ? 'danger' : 'success'">{{
            sensible(data.item)
          }}</b-badge>
        </span>
      </template>
      <template v-slot:cell(dcp)="data">
        <b-badge :variant="dcp(data.item) === 'Oui' ? 'danger' : 'success'">{{
          dcp(data.item)
        }}</b-badge>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <!-- {{ OBJETS }} -->
  </div>
</template>

<script>
import { maxBy } from "lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    fluxId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    showGoals: false,
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    concept: null,
    objet: null,
    // loading: true,
    tableFields: [
      { key: "business_object", label: "Objet Métier", sortable: true },
      { key: "dcp", label: "DCP", sortable: true },
      { key: "sensitivity", label: "Sensibilité", sortable: true },
    ],
    tableItems: [],
    loading: true,
  }),

  methods: {
    onRowSelected(items) {
      this.selectedObjects = items;
    },
    maxBy,
    dcp(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.dcp ? attribut.dcp === "Non" : false
        )
        ? "Non"
        : "Oui";
    },
    sensible(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.sensible ? attribut.sensible === 0 : true
        )
        ? 0
        : maxBy(objet.attributs, (attribut) => attribut.sensible || 0).sensible;
    },
  },
  created() {
    this.$store.dispatch("fluxe/fetchFluxObjects", this.fluxId).then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters("fluxe", ["FLUX_OBJECTS"]),
    ...mapGetters("application", ["APPLICATION"]),

    rows() {
      return this.tableItems.length;
    },
  },

  watch: {
    FLUX_OBJECTS() {
      this.tableItems = this.FLUX_OBJECTS.objet;
    },
  },
};
</script>
