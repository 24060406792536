var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mb-2"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des bénéficiaires ")]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('custom-add-button',{attrs:{"disabled":_vm.disabled,"text":"Ajouter un bénéficiaire"},on:{"click":_vm.addItem}})],1)]),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.tableFields,"responsive":"","sort-desc":_vm.sortDesc,"per-page":_vm.perPage,"current-page":_vm.currentPage,"show-empty":"","bordered":"","striped":""},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":_vm.disabled ? [] : ['edit', 'delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"editItem":function($event){return _vm.editItem(data.item)},"deleteItem":function($event){return _vm.deleteItem(data.item.id)},"cancelItem":_vm.cancelItem,"confirmItem":function($event){return _vm.confirmItem(data.item.id)}}})]}},{key:"cell(type)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.newBen.type.$error && _vm.$v.newBen.type.$dirty,
              },staticStyle:{"padding":"0 !important"},attrs:{"appendToBody":"","type":"text","placeholder":"Type bénéficiaire","options":_vm.BENEFICIAIRE_TYPES},on:{"input":function($event){(_vm.newBen.name = ''),
                  (_vm.newBen.organisation = _vm.newBen.type
                    ? _vm.newBen.type === 'Externe'
                      ? 'Aucune'
                      : null
                    : null)}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.newBen.type),callback:function ($$v) {_vm.$set(_vm.newBen, "type", $$v)},expression:"newBen.type"}}),(_vm.$v.newBen.type.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBen.type.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(name)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-link',{attrs:{"to":{
              name: 'show-payee',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'payee',
              },
            }}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.newBen.name.$error && _vm.$v.newBen.name.$dirty,
              },staticStyle:{"padding":"0 !important"},attrs:{"type":"text","placeholder":"Bénéficiaires","appendToBody":"","options":_vm.beneficairesList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.newBen.name),callback:function ($$v) {_vm.$set(_vm.newBen, "name", $$v)},expression:"newBen.name"}}),(_vm.$v.newBen.name.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBen.name.required ? "Champ obligatoire" : !_vm.$v.newBen.name.minLength ? `Le champ doit contenir au moins ${_vm.$v.newBen.name.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1)]}},{key:"cell(organisation)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value ? data.value.libelle : "Aucune")+" ")]):_c('div',[_c('v-select',{class:{
                'is-invalid form-control p-0':
                  _vm.$v.newBen.organisation.$error &&
                  _vm.$v.newBen.organisation.$dirty,
              },staticStyle:{"padding":"0 !important"},attrs:{"append-to-body":"","label":"text","reduce":(org) => org.value,"type":"text","options":_vm.organisationsList,"placeholder":"Organisation","disabled":_vm.newBen.type === 'Externe'},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.newBen.organisation),callback:function ($$v) {_vm.$set(_vm.newBen, "organisation", $$v)},expression:"newBen.organisation"}}),(_vm.$v.newBen.organisation.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBen.organisation.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(volume)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newBen.volume.$error && _vm.$v.newBen.volume.$dirty,
              },attrs:{"type":"number","placeholder":"Volume","min":"0"},model:{value:(_vm.newBen.volume),callback:function ($$v) {_vm.$set(_vm.newBen, "volume", $$v)},expression:"newBen.volume"}}),(_vm.$v.newBen.volume.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBen.volume.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }