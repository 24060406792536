<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche Application :
          </span>

          <span style="font-size: 25px" class="font-weight-bold">{{
            APPLICATION.name
          }}</span>
        </p>

        <div class="row mb-4">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
              @print="
                $router.push({
                  name: 'print-application',
                  params: { ...$route.params },
                })
              "
              mode="show"
            />
          </div>
        </div>
      </div>
      <div class="d-flex m-0 p-0" style="gap: 5px">
        <div class="shdow col-md-4 col-12">
          <div class="d-flex justify-content-between align-items-center">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="margin: 6.656px 0 !important"
            >
              Informations générales
            </p>
            <button
              v-if="
                (isAdmin || isSuper || isArchitect) &&
                (!editMode || editing !== 'info')
              "
              :disabled="editMode && editing !== 'info'"
              class="p-0 bg-white border-0"
              style="font-size: 30px"
              :style="{
                color: editMode && editing !== 'info' ? 'gray' : '#007bff',
              }"
              @click.prevent="(editMode = !editMode), (editing = 'info')"
            >
              <i class="mdi mdi-file-document-edit"></i>
            </button>
            <div
              v-else-if="isAdmin || isSuper || isArchitect"
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                size="sm"
                @click="updateItem"
                :disabled="submitingForm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="editMode = !editMode"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
          <div class="row" v-if="!editMode || editing !== 'info'">
            <div class="col-12 col-md-12">
              <div class="my-4">
                <span>Nom : </span>
                <span class="font-weight-bold">{{ APPLICATION.name }}</span>
              </div>
              <div class="my-4">
                <span>Libellé court : </span>
                <span class="font-weight-bold">{{
                  APPLICATION.libellecour
                }}</span>
              </div>
              <div class="my-4">
                <span>Statut : </span>
                <span class="font-weight-bold">{{ APPLICATION.status }}</span>
              </div>
              <div class="my-4">
                <span>Ligne métier : </span>
                <span class="font-weight-bold">{{
                  APPLICATION.businessLine ? APPLICATION.businessLine.length : 0
                }}</span>
              </div>
              <div class="my-4">
                <span>Type SI : </span>
                <span class="font-weight-bold">{{
                  APPLICATION.typeSi || "-"
                }}</span>
              </div>
              <div class="my-4">
                <span>Patrimoine : </span>
                <span class="font-weight-bold">{{
                  APPLICATION.patrimoine ? APPLICATION.patrimoine.name : "-"
                }}</span>
              </div>
            </div>
            <div class="col-12 col-md-12" style="margin-top: -25px">
              <div class="my-4">
                <span>PO : </span>
                <span class="font-weight-bold">
                  {{ APPLICATION.po ? APPLICATION.po.firstname : "" }}
                  {{ APPLICATION.po ? APPLICATION.po.lastname : "" }}
                </span>
              </div>
              <div class="my-4">
                <span>Libellé long : </span>
                <span class="font-weight-bold">{{
                  APPLICATION.description
                }}</span>
              </div>
              <div class="my-4">
                <span>Type d'applications : </span>
                <span class="font-weight-bold">{{ APPLICATION.type }}</span>
              </div>
              <div class="my-4">
                <span>Portée : </span>
                <span class="font-weight-bold">{{
                  APPLICATION.portee || "-"
                }}</span>
              </div>
              <div class="my-4 d-flex">
                <span class="d-block my-auto mr-1">Taux : </span>
                <b-progress
                  max="100"
                  class="col-md-8 p-0"
                  show-progress
                  height="15px"
                >
                  <b-progress-bar
                    :value="APPLICATION.taux"
                    :label="`${APPLICATION.taux}%`"
                    :style="`background-color: ${
                      APPLICATION.taux > 20 && APPLICATION.taux <= 70
                        ? '#f5f245'
                        : APPLICATION.taux > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-12 col-md-12">
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Nom:</label>
                </div>
                <div class="col-md-8">
                  <b-form-input
                    type="text"
                    placeholder="Libellé"
                    :class="{
                      'is-invalid':
                        $v.newApplication.name.$error &&
                        $v.newApplication.name.$dirty,
                    }"
                    v-model="newApplication.name"
                  ></b-form-input>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.name.$dirty"
                  >
                    {{
                      !$v.newApplication.name.required
                        ? "champ obligatoire"
                        : !$v.newApplication.name.minLength
                        ? `Le champ doit contenir au moins ${$v.newApplication.name.$params.minLength.min} caractères.`
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Description:</label>
                </div>
                <div class="col-md-8">
                  <b-form-textarea
                    type="text"
                    placeholder="Description"
                    v-model="newApplication.description"
                  ></b-form-textarea>
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Libellé court:</label>
                </div>
                <div class="col-md-8">
                  <b-form-input
                    type="text"
                    placeholder="Libellé court"
                    :class="{
                      'is-invalid':
                        $v.newApplication.libellecour.$error &&
                        $v.newApplication.libellecour.$dirty,
                    }"
                    v-model="newApplication.libellecour"
                  ></b-form-input>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.libellecour.$dirty"
                  >
                    {{
                      !$v.newApplication.libellecour.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Statut:</label>
                </div>
                <div class="col-md-8">
                  <v-select
                    type="text"
                    placeholder="Statut"
                    v-model="newApplication.status"
                    :options="['En prod', 'En cours', 'Terminé']"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newApplication.status.$error &&
                        $v.newApplication.status.$dirty,
                    }"
                  ></v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.status.$dirty"
                  >
                    {{
                      !$v.newApplication.status.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Ligne métier:</label>
                </div>
                <div class="col-md-8">
                  <multiselect
                    multiple
                    label="name"
                    track-by="id"
                    placeholder="Ligne métier"
                    :searchable="true"
                    v-model="newApplication.businessLine"
                    :options="businessesLigne"
                  ></multiselect>
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Type SI:</label>
                </div>
                <div class="col-md-8">
                  <v-select
                    type="text"
                    placeholder="Type SI"
                    v-model="newApplication.typeSi"
                    :options="['SI Interne', 'Lorem', 'Ipsum']"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newApplication.typeSi.$error &&
                        $v.newApplication.typeSi.$dirty,
                    }"
                  ></v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.typeSi.$dirty"
                  >
                    {{
                      !$v.newApplication.typeSi.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12" style="margin-top: -14px">
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Patrimoine:</label>
                </div>
                <div class="col-md-8">
                  <v-select
                    type="text"
                    label="text"
                    :reduce="(patrimoine) => patrimoine.value"
                    placeholder="Patrimoine"
                    v-model="newApplication.patrimoine"
                    :options="patrimoinesList"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newApplication.patrimoine.$error &&
                        $v.newApplication.patrimoine.$dirty,
                    }"
                  ></v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.patrimoine.$dirty"
                  >
                    {{
                      !$v.newApplication.patrimoine.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">PO:</label>
                </div>
                <div class="col-md-8">
                  <v-select
                    label="text"
                    :reduce="(user) => user.value"
                    :options="ownersList"
                    placeholder="PO"
                    v-model="newApplication.po"
                    :class="{
                      'is-invalid':
                        $v.newApplication.po.$error &&
                        $v.newApplication.po.$dirty,
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.po.$dirty"
                  >
                    {{
                      !$v.newApplication.po.required ? "champ obligatoire" : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Type:</label>
                </div>
                <div class="col-md-8">
                  <v-select
                    type="text"
                    placeholder="Type"
                    v-model="newApplication.type"
                    :options="['SI Interne', 'Lorem', 'Ipsum']"
                    :class="{
                      'is-invalid':
                        $v.newApplication.type.$error &&
                        $v.newApplication.type.$dirty,
                    }"
                  ></v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.type.$dirty"
                  >
                    {{
                      !$v.newApplication.type.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Portée:</label>
                </div>
                <div class="col-md-8">
                  <v-select
                    type="text"
                    placeholder="Portée"
                    v-model="newApplication.portee"
                    :options="['Local', 'consectetur', 'aut']"
                    :class="{
                      'is-invalid':
                        $v.newApplication.portee.$error &&
                        $v.newApplication.portee.$dirty,
                    }"
                  ></v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newApplication.portee.$dirty"
                  >
                    {{
                      !$v.newApplication.portee.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="mb-0 font-weight-bold">Taux:</label>
                </div>
                <div class="col-md-8">
                  <section class="simple-slider col-md-12 p-0">
                    <vue-slide-bar class="pt-3" v-model="newApplication.taux" />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shdow col-md-4 col-12">
          <div class="d-flex justify-content-between align-items-center">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="margin: 6.656px 0 !important"
            >
              Documents
            </p>
            <button
              v-if="
                (isAdmin || isSuper || isArchitect) &&
                (!editMode || editing !== 'documents')
              "
              :disabled="editMode && editing !== 'documents'"
              class="p-0 bg-white border-0"
              style="font-size: 30px"
              :style="{
                color: editMode && editing !== 'documents' ? 'gray' : '#007bff',
              }"
              @click.prevent="(editMode = !editMode), (editing = 'documents')"
            >
              <i class="mdi mdi-file-document-edit"></i>
            </button>
            <div
              v-else-if="isAdmin || isSuper || isArchitect"
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                size="sm"
                @click="updateItem"
                :disabled="submitingForm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="editMode = !editMode"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
          <!-- <FilePicker @change="filePickerChanger" class="font-weight-normal" /> -->
          <div v-if="!editMode || editing != 'documents'">
            <p>
              <span style="color: black">
                <div
                  class="text-center text-gray col-12 p-1"
                  v-if="
                    !APPLICATION.fileApplications ||
                    !APPLICATION.fileApplications.length
                  "
                >
                  Aucun document
                </div>
                <b-list-group v-else class="mt-3">
                  <b-list-group-item
                    v-for="(livrable, index) in APPLICATION.fileApplications"
                    :key="index"
                    class="d-flex"
                    ><i
                      class="fa my-auto pr-1 fa-paperclip"
                      aria-hidden="true"
                    ></i>
                    <a
                      target="_blank"
                      :href="IMG_URL + livrable.fileUrl"
                      class="text-truncate"
                    >
                      {{ livrable.filename }}</a
                    >
                    <i
                      v-if="editMode && editing == 'livrable'"
                      @click="deleteLivrable(livrable.id)"
                      class="fa my-auto fa-times text-danger ml-auto"
                      aria-hidden="true"
                      style="cursor: pointer"
                    ></i
                  ></b-list-group-item>
                </b-list-group>
              </span>
            </p>
          </div>
          <!-- UPDATE SECTION -->
          <div v-else>
            <b-form-group
              label=""
              class="font-weight-bold"
              label-cols-sm="0"
              label-align-sm="left"
            >
              <FilePicker @change="filePickerChanger" @click.prevent="" />
              <span style="color: black" class="font-weight-bold">
                <b-list-group class="mt-3">
                  <b-list-group-item
                    v-for="(livrable, index) in APPLICATION.fileApplications"
                    :key="index"
                    class="d-flex m-0"
                    ><i
                      class="fa my-auto pr-1 fa-paperclip"
                      aria-hidden="true"
                    ></i>
                    <a class="text-truncate" :href="IMG_URL + livrable.fileUrl">
                      {{ livrable.filename }}</a
                    >
                    <i
                      @click="deleteLivrable(livrable.id)"
                      class="fa my-auto fa-times text-danger ml-auto"
                      aria-hidden="true"
                      style="cursor: pointer"
                    ></i
                  ></b-list-group-item>
                </b-list-group>
              </span>
            </b-form-group>
          </div>
        </div>
        <div class="shdow col-md-4 col-12">
          <div class="d-flex justify-content-between align-items-center">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="margin: 6.656px 0 !important"
            >
              Couvertures
            </p>
            <button
              v-if="
                (isAdmin || isSuper || isArchitect) &&
                (!editMode || editing !== 'couverture')
              "
              :disabled="editMode && editing !== 'couverture'"
              class="p-0 bg-white border-0"
              style="font-size: 30px"
              :style="{
                color:
                  editMode && editing !== 'couverture' ? 'gray' : '#007bff',
              }"
              @click.prevent="(editMode = !editMode), (editing = 'couverture')"
            >
              <i class="mdi mdi-file-document-edit"></i>
            </button>
            <div
              v-else-if="isAdmin || isSuper || isArchitect"
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                size="sm"
                @click="updateItem"
                :disabled="submitingForm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="editMode = !editMode"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
          <div class="col-12 p-0" v-if="!editMode || editing !== 'couverture'">
            <div>
              <div class="my-4">
                <div class="row m-0 p-0" style="gap: 5px">
                  <div
                    class="text-center text-gray col-12 p-1"
                    v-if="!couvertures.length"
                  >
                    Aucune couverture
                  </div>
                  <div
                    v-else
                    style="height: 50px; position: relative; overflow: visible"
                    class="shdow"
                    v-for="(covert, index) in APPLICATION.couvertures"
                    :key="index"
                  >
                    <p class="my-auto">
                      <country-flag
                        :country="covert.countryCode"
                        size="small"
                      />{{ covert.country || "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0" v-else>
            <div class="col-md-12 p-0">
              <coverage
                @change="changeCouverture"
                @delete="deleteCouverture"
                :AppCouvertures="couvertures"
              />
            </div>
          </div>
          <div class="d-flex mt-3 justify-content-between align-items-center">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="margin: 6.656px 0 !important"
            >
              Pays Responsables
            </p>
            <button
              v-if="
                (isAdmin || isSuper || isArchitect) &&
                (!editMode || editing !== 'pay')
              "
              :disabled="editMode && editing !== 'pay'"
              class="p-0 bg-white border-0"
              style="font-size: 30px"
              :style="{
                color: editMode && editing !== 'pay' ? 'gray' : '#007bff',
              }"
              @click.prevent="(editMode = !editMode), (editing = 'pay')"
            >
              <i class="mdi mdi-file-document-edit"></i>
            </button>
            <div
              v-else-if="isAdmin || isSuper || isArchitect"
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                size="sm"
                @click="updateItem"
                :disabled="submitingForm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="cancelEdit"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <hr class="w-100 mt-1 mb-2 bg-secondary" />
          <div class="mt-3" v-if="!editMode || editing !== 'pay'">
            <div class="row m-0 p-0">
              <div
                class="text-center text-gray col-12 p-1"
                v-if="!paysResponable.length"
              >
                Aucune Pays Responsables
              </div>
              <div
                v-else
                style="height: 50px; position: relative; overflow: visible"
                class="shdow"
                v-for="(covert, index) in paysResponable"
                :key="index"
              >
                <p>
                  <country-flag
                    :country="covert.countryCode"
                    size="small"
                    v-show="covert.countryCode"
                  />{{ covert.country }}
                </p>

                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i
                      @click="deletePaysResponsable(index)"
                      class="mdi mdi-close-circle text-danger"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0" v-else>
            <PaysResponsable
              :paysResponsableFunction="paysResponsableFunction"
            />
            <div class="row m-0 p-0">
              <div
                style="height: 50px; position: relative; overflow: visible"
                class="shdow"
                v-for="(covert, index) in paysResponable"
                :key="index"
              >
                <p>
                  <country-flag
                    :country="covert.countryCode"
                    size="small"
                    v-show="covert.countryCode"
                  />{{ covert.country }}
                </p>

                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i
                      @click="deletePaysResponsable(index, covert.id)"
                      class="mdi mdi-close-circle text-danger"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="row mt-3">
        <div class="col-12">
          <b-tabs
            :value="$store.state.tabs.activeTab"
            @changed="activeTab = $store.state.tabs.activeTab"
            @activate-tab="
              (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Référents">
              <application-referent
                :responsables="ownersList"
                :libelle="APPLICATION.name"
                :disabled="disabled"
              />
            </b-tab>
            <b-tab title="Bénéficiaires">
              <application-payee :disabled="disabled" />
            </b-tab>
            <b-tab title="Capacité">
              <application-capacity :disabled="disabled" />
            </b-tab>
            <b-tab title="Processus supportés">
              <application-process :disabled="disabled" />
            </b-tab>
            <b-tab title="Fonctions">
              <application-function :disabled="disabled" />
            </b-tab>
            <b-tab title="API Exposées">
              <application-api
                :libelle="APPLICATION.name"
                :disabled="disabled"
              />
            </b-tab>
            <b-tab title="Données & GDPR">
              <application-data :disabled="disabled" />
            </b-tab>

            <b-tab title="Composants Techniques">
              <application-technical-component :disabled="disabled" />
            </b-tab>

            <b-tab title="Flux et échanges">
              <application-exchange
                :libelle="APPLICATION.name"
                :disabled="disabled"
              />
            </b-tab>

            <b-tab title="Transformations">
              <application-transformation />
            </b-tab>
            <b-tab title="Cadres implémentés"
              ><cadres-table :disabled="disabled" /></b-tab
          ></b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import ApplicationReferent from "./components/ApplicationReferent.vue";
import ApplicationPayee from "./components/ApplicationPayee.vue";
import ApplicationCapacity from "./components/ApplicationCapacity.vue";
import ApplicationProcess from "./components/ApplicationProcess.vue";
import ApplicationFunction from "./components/ApplicationFunction.vue";
import ApplicationApi from "./components/ApplicationApi.vue";
import ApplicationData from "./components/ApplicationData.vue";
import ApplicationTechnicalComponent from "./components/ApplicationTechnicalComponent.vue";
import ApplicationExchange from "./components/ApplicationExchange.vue";
import ApplicationTransformation from "./components/ApplicationTransformation.vue";
import CadresTable from "./components/CadresTable";
import Coverage from "./components/Coverage.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import VueSlideBar from "vue-slide-bar";
import CountryFlag from "vue-country-flag";
import FilePicker from "@/components/custom/FilePicker";
import PaysResponsable from "@/components/custom/PaysResponsable";
import { IMG_URL } from "@/helpers/services/api";
import { APPLICATION_URL, USER_URL } from "@/helpers/constURL";
export default {
  components: {
    PaysResponsable,
    FilePicker,
    CountryFlag,
    ModalActions,
    VueSlideBar,
    ApplicationReferent,
    ApplicationPayee,
    ApplicationCapacity,
    ApplicationProcess,
    ApplicationFunction,
    ApplicationApi,
    ApplicationData,
    ApplicationTechnicalComponent,
    ApplicationExchange,
    ApplicationTransformation,
    Coverage,
    CadresTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    editing: "",
    IMG_URL,
    paysResponable: [],
    newApplication: {
      name: "",
      description: "",
      patrimoine: "", //
      libellecour: "",
      po: "",
      refSa: "",
      taux: 0, //
      status: "", //
      typeSi: "",
      type: "",

      businessLine: [],
    },
    couvertures: [],
    deletedCouvertures: [],
    livrables: [],
    deletedPays: [],
  }),
  validations: {
    newApplication: {
      name: {
        required,
        minLength: minLength(5),
      },
      libellecour: {
        required,
      },
      status: {
        required,
      },
      typeSi: {
        required,
      },
      patrimoine: {
        required,
      },
      po: {
        required,
      },
      type: {
        required,
      },
      portee: {
        required,
      },
    },
  },
  methods: {
    filePickerChanger(files) {
      this.livrables = files;
    },
    cancelEdit() {
      this.$store
        .dispatch("application/fetchApplication", this.$route.params.id)
        .then(() => {
          this.editMode = false;
          this.deletedCouvertures = [];
        });
    },
    deletePaysResponsable(index, id) {
      if (id) this.deletedPays.push(id);

      this.paysResponable.splice(index, 1);
    },
    paysResponsableFunction(location) {
      (!this.paysResponable.find(
        (pays) => pays.countryCode === location.countryCode
      ) &&
        this.paysResponable.push(this.clone(location))) ||
        Swal.fire("Impossible d'ajouter un pays déjà existant !", "", "error");
    },
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    changeCouverture(couvertures) {
      this.couvertures = couvertures;
    },
    deleteCouverture(id) {
      this.deletedCouvertures.push(id);
    },
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.deletedCouvertures.forEach((id) => {
            this.$store.dispatch("couverture/deleteCouverture", id).then(() => {
              this.$store.dispatch(
                "application/fetchApplication",
                this.$route.params.id
              );
            });
          });

        this.$store
          .dispatch("application/updateApplication", {
            id: this.$route.params.id,
            ...this.newApplication,
            businessLine: this.newApplication.businessLine.map(
              (ligne) => ligne["@id"]
            ),
            couversturesResp: this.paysResponable.map((element) => ({
              ...element,
            })),
          })
          .then(() => {
            this.couvertures.forEach((element) => {
              if (!element.id)
                this.$store
                  .dispatch("couverture/createCouverture", {
                    ...element,
                    application: `/api/${APPLICATION_URL}/${this.APPLICATION.id}`,
                  })
                  .then(() => {
                    this.$store.dispatch(
                      "application/fetchApplication",
                      this.$route.params.id
                    );
                  });
            });

            this.livrables.map((file, index) => {
              file.append("application", this.APPLICATION["@id"]);
              this.$store
                .dispatch("application/createFileApplication", file)
                .then(() => {
                  if (this.livrables.length - 1 === index) {
                    this.$store.dispatch(
                      "application/fetchApplication",
                      this.$route.params.id
                    );
                    this.livrables = [];
                  }
                });
            });
            this.$store
              .dispatch("application/fetchApplication", this.$route.params.id)
              .then(() => {
                Swal.fire("L'application est mise à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
    deleteLivrable(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("application/deleteFileApplication", id)
            .then(() => {
              this.$store
                .dispatch("application/fetchApplication", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplication", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("capaciteSi/fetchAllCapacites");
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("businessLine/fetchAllBusinessLines");
  },
  computed: {
    // ...mapGetters("capaciteSi", ["CAPACITE_SI", "CAPACITES_SI"]),
    ...mapGetters("application", ["APPLICATION"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("businessLine", ["BUSINESS_LINES"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    capaciteRatechements() {
      return this.CAPACITES_SI;
    },
    // applicationsList() {
    //   return this.APPLICATIONS;
    // },
    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    ownersList() {
      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        text: `${responsable.firstname} ${responsable.lastname}`,
        value: `/api/${USER_URL}/${responsable.id}`,
      }));
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        text: organisation.libelle,
        value: organisation["@id"],
      }));
    },
    businessesLigne() {
      return this.BUSINESS_LINES;
    },
    disabled() {
      return !(this.isArchitect || this.isSuper || this.isAdmin);
    },
  },

  watch: {
    APPLICATION: function () {
      this.submitingForm = false;

      this.newApplication = {
        ...this.newApplication,
        name: this.APPLICATION.name,
        description: this.APPLICATION.description,
        status: this.APPLICATION.status,
        po: this.APPLICATION.po["@id"],
        refSa: this.APPLICATION.refSa,
        patrimoine: this.APPLICATION.patrimoine["@id"],
        taux: this.APPLICATION.taux,
        libellecour: this.APPLICATION.libellecour,
        businessLine: this.APPLICATION.businessLine,
        typeSi: this.APPLICATION.typeSi,

        type: this.APPLICATION.type,
        portee: this.APPLICATION.portee,
      };
      this.couvertures = this.APPLICATION.couvertures;
      this.paysResponable = this.APPLICATION.couversturesResp;
      // this.livrables = this.APPLICATION.fileApplications;

      window.console.log(this.APPLICATION, "application");
    },
  },
};
</script>

<style></style>
