<template>
  <div>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des composants techniques
      </span>
      <div class="d-flex justify-content-end">
        <CustomAddButton
          :disabled="disabled || (!composantTypes.length ? true : false)"
          @click="addItem"
          text="Ajouter un composant tech.
"
        ></CustomAddButton>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive
          bordered
          :per-page="perPage"
          :current-page="currentPage"
          striped
          show-empty
          :busy="loading"
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template #cell(type)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                appendToBody
                :options="composantTypes"
                type="text"
                :value="data.value"
                v-model="type"
                placeholder="Type"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.type.$error && $v.type.$dirty,
                }"
              ></v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.type.$dirty"
              >
                {{ !$v.type.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template #cell(versions)="data">
            <!-- <span
              @click="showVersions(data.item)"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              {{ data.value.length }}
            </span> -->
            <b-badge
              v-if="data.value.length"
              style="
                cursor: pointer;
                background-color: #adb5bd;
                border-color: #adb5bd;
              "
              @click="showVersions(data.item)"
              pill
            >
              {{ data.value.length }}
            </b-badge>
            <b-badge
              style="
                cursor: pointer;
                background-color: #adb5bd;
                border-color: #adb5bd;
              "
              v-else
              pill
            >
              {{ data.value.length }}
            </b-badge>
            <!-- <b-form-input v-else type="text" :value="data.value"></b-form-input> -->
            <span>
              <!-- {{ data.value.length }} -->
            </span>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-composant',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              {{ data.value }}
            </b-link>
            <div v-else>
              <v-select
                label="name"
                placeholder="Composant technique"
                style="padding: 0 !important"
                v-model="composant"
                appendToBody
                :options="composantsList"
                :class="{
                  'is-invalid form-control p-0':
                    $v.composant.$error && $v.composant.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.composant.$dirty"
              >
                {{ !$v.composant.required ? "champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.index)"
              @deleteItem="deleteItem(data.item)"
              @cancelItem="cancelItem(data.item)"
              @confirmItem="confirmItem(data.item)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
      <!-- {{ APPLICATION_COMPOSANTS }} -->
      <div v-if="showVersionsModal" to="modals-xyz-500">
        <modal @close="showVersionsModal = !showVersionsModal">
          <VersionList :composant="composant" />
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { APPLICATION_URL } from "@/helpers/constURL";
import CustomAddButton from "@/components/custom/CustomAddButton";
import Modal from "@/components/custom/Modal.vue";
import VersionList from "./VersionList";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { uniq } from "lodash";

export default {
  components: { TableActions, CustomAddButton, VersionList, Modal },
  props: {
    composants: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    perPage: 5,
    currentPage: 1,
    loading: true,
    showGoals: false,

    tableFields: [
      { key: "type", label: "Composant de type", sortable: true },
      { key: "name", label: "Composant", sortable: true },
      { key: "versions", label: "Version", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
    type: "",
    composant: null,
    showVersionsModal: false,
  }),
  validations: {
    type: { required },
    composant: { required },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationComposants", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });

    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchBeneficiaireTypes");
    this.$store.dispatch("composant/fetchAllComposants");
  },
  computed: {
    ...mapGetters("application", {
      APPLICATION_COMPOSANTS: "COMPOSANTS",
    }),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("type", ["BENEFICIAIRE_TYPES"]),
    ...mapGetters("composant", ["COMPOSANTS"]),

    rows() {
      return this.tableItems.length;
    },
    organisationsList() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    composantTypes() {
      // return [
      //   "BDD",
      //   "Framework logiciel",
      //   "Hébergement",
      //   "Réseau & Sécurité",
      //   "Système d’échange",
      //   "Autre",
      // ];
      return uniq(
        this.COMPOSANTS.filter(
          ({ id }) => !this.tableItems.find((item) => item.id === id)
        ).map(({ type }) => type)
      );
    },
    composantsList() {
      return this.type
        ? this.COMPOSANTS.filter(
            ({ type, id }) =>
              type === this.type &&
              !this.tableItems.find((item) => item.id === id)
          )
        : [];
      // return this.COMPOSANTS;
    },
  },
  methods: {
    uniq,
    showVersions(item) {
      this.composant = item;
      this.showVersionsModal = !this.showVersionsModal;
    },

    editItem(index) {
      this.$store
        .dispatch(
          "application/fetchApplicationComposants",
          this.$route.params.id
        )
        .then(() => {
          this.tableItems = this.tableItems.map((challenge, i) => {
            return index === i
              ? {
                  ...challenge,
                  editMode: true,
                }
              : challenge;
          });
        });
    },
    cancelItem(item) {
      if (item.name === "" && item.type === "")
        this.$store
          .dispatch(
            "application/fetchApplicationComposants",
            this.$route.params.id
          )
          .then(() => {
            this.$v.$reset();
          });
      else (item.name = ""), (item.type = "");
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          window.console.log(item);
          var composant = this.COMPOSANTS.find(
            (composant) => composant.id == item.id
          );
          var applications = composant.applications
            .map((application) => application["@id"])
            .filter(
              (app) => app != `/api/${APPLICATION_URL}/${this.$route.params.id}`
            );

          this.$store
            .dispatch("composant/updateComposant", {
              id: item.id,
              applications: applications,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationComposants",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        delete item.editMode;

        if (item.id)
          this.$store
            .dispatch("composant/updateComposant", {
              id: item.id,
              name: item.name,
              type: item.type,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationComposants",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("La fonction est mise à jour !", "", "success");
                  this.type = "";
                  this.$v.$reset();
                });
            });
        else {
          var applications = this.composant.applications.map(
            (application) => application["@id"]
          );
          applications.push(`/api/${APPLICATION_URL}/${this.$route.params.id}`);

          this.$store
            .dispatch("composant/updateComposant", {
              applications: applications,
              id: this.composant.id,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationComposants",
                  this.$route.params.id
                )

                .then(() => {
                  Swal.fire("Le composant est bien créé !", "", "success");
                  this.type = "";
                  this.$v.$reset();
                });
            });
        }
      }
    },
    addItem() {
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      )
        this.tableItems = [
          {
            editMode: true,
          },
          ...this.tableItems,
        ];

      this.composant = null;
    },
  },
  watch: {
    APPLICATION_COMPOSANTS() {
      this.tableItems = this.APPLICATION_COMPOSANTS
        ? this.APPLICATION_COMPOSANTS.composants
        : [];
    },
  },
};
</script>

<style></style>
