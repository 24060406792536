<template>
  <div>
    <Places :testCouverture="sendingFunction" />
    <div class="row m-0 p-0" style="gap: 5px">
      <div
        style="height: 50px; position: relative; overflow: visible"
        class="shdow"
        v-for="(covert, index) in couvertures"
        :key="index"
      >
        <p>
          <country-flag :country="covert.countryCode" size="small" />{{
            covert.country || ""
          }}
        </p>

        <div style="position: absolute; top: -5px; right: -5px">
          <span style="cursor: pointer"
            ><i
              @click="deleteCouverture(index)"
              class="mdi mdi-close-circle text-danger"
            ></i
          ></span>
        </div>
      </div>
    </div>
    <!-- <div class="row m-0 p-0">
      <div style="height: 50px; position: relative; overflow: visible" class="shdow mr-2">
        <p><i class="flag-icon flag-icon-it" /> Italie</p>
        <div style="position: absolute; top: -5px; right: -5px">
          <span style="cursor: pointer"
            ><i class="mdi mdi-close-circle text-danger"></i
          ></span>
        </div>
      </div>
      <div style="height: 50px; position: relative; overflow: visible" class="shdow mr-2">
        <p><i class="flag-icon flag-icon-fr" /> France</p>
        <div style="position: absolute; top: -5px; right: -5px">
          <span style="cursor: pointer"
            ><i class="mdi mdi-close-circle text-danger"></i
          ></span>
        </div>
      </div>

      <div class="d-flex align-items-center ml-2">
        <a style="font-size: 25px" href="#" class="text-secondary" @click.prevent>
          <i class="mdi mdi-plus-circle-outline"></i
        ></a>
      </div>
    </div> -->
  </div>
</template>

<script>
import Places from "@/components/custom/Places.vue";
import CountryFlag from "vue-country-flag";
import Swal from "sweetalert2";

export default {
  components: {
    Places,
    CountryFlag,
  },
  props: {
    AppCouvertures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      couvertures: [],
    };
  },
  mounted() {
    this.couvertures = this.AppCouvertures;
  },
  methods: {
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      (!this.couvertures.find(
        (couverture) => couverture.countryCode === location.countryCode
      ) &&
        this.couvertures.push(this.clone(location))) ||
        Swal.fire("Impossible d'ajouter une couverture déjà existante !", "", "error");
    },
    deleteCouverture(index) {
      if (this.couvertures[index].id) this.$emit("delete", this.couvertures[index].id);
      this.couvertures.splice(index, 1);
    },
  },
  watch: {
    couvertures() {
      this.$emit("change", this.couvertures);
    },
    AppCouvertures() {
      this.couvertures = this.AppCouvertures;
    },
  },
};
</script>

<style></style>
