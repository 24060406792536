<template>
  <div>
    <div v-if="false" class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto">
        Liste des objets manipulés
      </span>

      <div class="row mb-0">
        <div class="col-12 d-flex justify-content-end">
          <custom-add-button
            :disabled="disabled"
            text="Ajouter un traitement"
            @click="addItem"
          ></custom-add-button>
        </div>
      </div>
    </div>
    <div v-if="false" class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      :items="tableItems"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(business_object)="data">
        <b-link
          :to="{
            name: 'show-business-object',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
          v-if="!data.item.hasOwnProperty('editMode')"
        >
          {{ data.item ? data.item.name : "-" }}
        </b-link>
        <v-select
          v-else
          appendToBody
          label="name"
          :options="objectsList"
          v-model="objet"
          placeholder="Objet"
        ></v-select>
      </template>
      <template v-slot:cell(sensitivity)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <b-badge :variant="sensible(data.item) ? 'danger' : 'success'">{{
            sensible(data.item)
          }}</b-badge>
        </span>

        <b-form-input type="number" disabled :value="sensible(objet)" v-else>
        </b-form-input>
      </template>
      <template v-slot:cell(dcp)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <span v-if="!data.item.hasOwnProperty('editMode')">
            <b-badge
              :variant="dcp(data.item) === 'Oui' ? 'danger' : 'success'"
              >{{ dcp(data.item) }}</b-badge
            >
          </span>
        </span>

        <v-select
          appendToBody
          disabled
          :options="['Oui', 'Non']"
          :value="dcp(objet)"
          v-else
        />
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @editItem="editItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @cancelItem="cancelItem"
          @confirmItem="confirmItem(data.item)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <!-- {{ OBJETS }} -->
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { APPLICATION_URL } from "@/helpers/constURL";
import { mapGetters } from "vuex";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { maxBy } from "lodash";
import { required } from "vuelidate/lib/validators";

export default {
  components: { TableActions, CustomAddButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showGoals: false,
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    concept: null,
    objet: null,
    loading: true,
    tableFields: [
      { key: "business_object", label: "Objet Métier", sortable: true },
      { key: "dcp", label: "DCP", sortable: true },
      { key: "sensitivity", label: "Sensibilité", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableItems: [],
  }),
  validations: {
    objet: {
      required,
    },
  },
  methods: {
    maxBy,
    dcp(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.dcp ? attribut.dcp === "Non" : false
        )
        ? "Non"
        : "Oui";
    },
    sensible(objet) {
      return !objet ||
        !objet.attributs ||
        !objet.attributs.length ||
        objet.attributs.every((attribut) =>
          attribut.sensible ? attribut.sensible === 0 : true
        )
        ? 0
        : maxBy(objet.attributs, (attribut) => attribut.sensible || 0).sensible;
    },

    editItem(data) {
      this.$store
        .dispatch("application/fetchApplicationObjets", this.$route.params.id)
        .then(() => {
          this.objet = data;
          // this.newObjet.status = data.status;
          this.tableItems = this.tableItems.map((item) => {
            return item.id === data.id
              ? {
                  ...item,
                  editMode: true,
                  Modify: true,
                }
              : item;
          });
        });
    },
    cancelItem() {
      if (this.objet || this.concept) {
        this.objet = null;
        this.concept = null;
      } else
        this.$store.dispatch(
          "application/fetchApplicationObjets",
          this.$route.params.id
        );
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var objet = this.OBJETS.find(({ id }) => id === item.id);
          var applications = objet
            ? objet.applications.map((application) => application["@id"])
            : [];
          applications = applications.filter(
            (application) =>
              application !== `/api/${APPLICATION_URL}/${this.$route.params.id}`
          );
          this.$store
            .dispatch("objet/updateObjet", {
              id: item.id,
              applications: applications,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "application/fetchApplicationObjets",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    confirmItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.objet) {
          var applications = this.objet.applications.map((application) =>
            application["@id"] ? application["@id"] : application
          );
          applications.push(`/api/${APPLICATION_URL}/${this.$route.params.id}`);
          this.$store
            .dispatch("objet/updateObjet", {
              id: this.objet.id,
              applications: applications,
            })
            .then(() => {
              Swal.fire("Le Objet est mise à jour !", "", "success");
              this.$store
                .dispatch(
                  "application/fetchApplicationObjets",
                  this.$route.params.id
                )
                .then(() => {
                  this.objet = null;
                });
            });
        }
      }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationObjets", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
    this.$store.dispatch("objet/fetchAllObjets");
  },
  computed: {
    ...mapGetters("application", {
      APPLICATION_OBJECTS: "OBJECTS",
    }),
    ...mapGetters("objet", ["OBJETS"]),

    rows() {
      return this.tableItems.length;
    },

    objectsList() {
      return this.OBJETS.filter(
        (objet) => !this.tableItems.find((item) => item["@id"] === objet["@id"])
      );
    },
    selectedObject() {
      return this.objet
        ? this.OBJETS.find((obj) => obj["@id"] === this.objet)
        : null;
    },
  },
  watch: {
    APPLICATION_OBJECTS() {
      this.tableItems = this.APPLICATION_OBJECTS.objets;
    },
  },
};
</script>
